import {VIEW_ALL_APPOINTMENT_DATE, VIEW_ALL_APPOINTMENTS  } from "./constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";
import "firebase/storage";
import "firebase/storage";
import { set_snack_bar } from "../../common/snackbar/action";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  uploadString,
} from "firebase/storage";


export function Get_All_Appointment(token) {
  console.log(token)
  return (dispatch) => {
    dispatch(setLoader());
    // console.log(token);
    return fetch(
      UNIVERSAL.EMPLOYEE +
      "get-all-appointments",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("asiillllllllllllla", responseJson);
        if (responseJson.status) {
          dispatch({
            type: VIEW_ALL_APPOINTMENTS,
            payload: {
              data: responseJson.result.data.result,
              count: responseJson.result.data.result.length,
            },
          });
          dispatch(set_snack_bar(true, "Data View Sucessfully"));
          dispatch(unsetLoader());
        } else {
          dispatch({
            type: VIEW_ALL_APPOINTMENTS,
            payload: { data: [], count: 0 },
          });
          dispatch(set_snack_bar(true, responseJson.message));
          dispatch(unsetLoader());
        }
        dispatch(unsetLoader());
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function Get_All_Appointment_date(date, token) {
  console.log(date, token);
  return (dispatch) => {
    dispatch(setLoader());
    
    return fetch(
      UNIVERSAL.EMPLOYEE + "get-appointments-by-date/" + date,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("asiillllllllllllla", responseJson);

        // Check if the response is 404 or no appointments are found
        if (responseJson.httpStatusCode === 404 || !responseJson.status) {
          dispatch({
            type: VIEW_ALL_APPOINTMENT_DATE,
            payload: { data: [], count: 0 },
          });

          // Display a custom message if no appointments are found
          dispatch(set_snack_bar(true, responseJson.displayMessage || "No appointments found."));
          dispatch(unsetLoader());
        } else if (responseJson.status) {
          // Handle the successful response case
          dispatch({
            type: VIEW_ALL_APPOINTMENT_DATE,
            payload: {
              data: responseJson.result.data.result,
              count: responseJson.result.data.result.length,
            },
          });
          dispatch(set_snack_bar(true, "Data View Successfully"));
          dispatch(unsetLoader());
        } else {
          // Fallback for other error cases
          dispatch(set_snack_bar(true, responseJson.message || "Error fetching data"));
          dispatch(unsetLoader());
        }
      })
      .catch((error) => {
        console.error(error);
        dispatch(set_snack_bar(true, "Something went wrong! Please try again."));
        dispatch(unsetLoader());
      });
  };
}

export function UpdateClientNote(customerNumber,note,token) {
  return (dispatch) => {
    dispatch(setLoader());
      console.log(customerNumber,note,token)
    return fetch(UNIVERSAL.EMPLOYEE + "update-client-note/" + customerNumber, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        note:note,
      }),
    })
      .then((response) => response.json())

      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.status) {
          dispatch(Get_All_Appointment(token));
          dispatch(unsetLoader());
        } else {

        }
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, responseJson.message));
      })
      .catch((error) => {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}


