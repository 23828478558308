import React, { Component } from "react";
import { connect } from "react-redux";
import { close_snack_bar } from "../../../common/snackbar/action";
import { Add_Gift_Customer, Add_Gift_package, Add_services, Delete_all_giftCustomer, Delete_all_giftpackage, Update_All_Gift_Customer, Update_All_Gift_package, viewAllGiftPackage, viewAllGiftPackageforcutomer } from "../action";
import ProductService from "../Component/Gifts";
import {View_All_Service,View_All_Products} from "../../ProductService/action"
import {Add_Billinvoice} from "../../BillInvoice/action"
export class Controller extends Component {
  render() {
    return <ProductService {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    gift: store.gift,
    viewallservices: store.viewallservices,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    
    viewAllGiftPackage: (token) => {
      dispatch(viewAllGiftPackage(token));
    },
    View_All_Service: (token) => {
      dispatch(View_All_Service(token));
    },
    View_All_Products: (token) => {
      dispatch(View_All_Products(token));
    },
   viewAllGiftPackageforcutomer :(token)=>{
    dispatch(viewAllGiftPackageforcutomer(token));
   },
   Add_Billinvoice:(name, number, service, discount, token,price)=>{
    dispatch(Add_Billinvoice(name, number, service, discount, token,price));
   },
   Add_Gift_package:(giftName, packageDetails, price, token) =>{
    dispatch(Add_Gift_package(giftName, packageDetails, price, token));
   },
   Update_All_Gift_package:(giftName, packageDetails, price, id, token) =>{
    dispatch(Update_All_Gift_package(giftName, packageDetails, price, id, token));
   },
   Delete_all_giftpackage:(id,token) =>{
    dispatch(Delete_all_giftpackage(id,token));
   },
   Add_Gift_Customer:(giftName,customerNumber,name, packageDetails,expDate, couponCode, token)=>{
    dispatch(Add_Gift_Customer(giftName,customerNumber,name, packageDetails,expDate, couponCode, token));
   },
   Delete_all_giftCustomer:(id,token) =>{
    dispatch(Delete_all_giftCustomer(id,token));
   },
   Update_All_Gift_Customer:(giftName,customerNumber,name, packageDetails,expDate, couponCode,id, token)=>{
    dispatch(Update_All_Gift_Customer(giftName,customerNumber,name, packageDetails,expDate, couponCode,id, token));
   }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
