import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from "redux-thunk";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
// import Reducer from './src/screens/redux/Reducers';
import login from './auth/reducer';
import snackbar from './common/snackbar/reducer';
import loader from './common/loader/reducer';
import assets from "./pages/Assets/reducer";
import allemployee from "./pages/AllEmployee/reducers"
import viewallservices from './pages/ProductService/Reducer';
import client from './pages/AllClients/Reducer';
import gift from './pages/Gifts/Reducer';
import Viewallproducts from './pages/ProductService/Reducer';
import appointment from './pages/Appoitment/reducer'
import sawappoitment from './pages/UserAppoitment/reducer';
import billinvoice from './pages/BillInvoice/reducer';
const persistConfig = {
  key: 'root',
  storage,
}

//import banner from "./src/screens/Home/Reducers";

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);

// const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const reducer = combineReducers({
  snackbar,
  loader,
  login,
  assets,
  allemployee,
  viewallservices,
  client,
  gift,
  appointment,
  Viewallproducts,
  sawappoitment,
  billinvoice,
 
})

const persistedReducer = persistReducer(persistConfig, reducer)
const configureStore = (initialState) => createStoreWithMiddleware(persistedReducer, initialState);
const store = configureStore();
// const persistor = persistStore(store)
// let store = createStore(persistedReducer)
let persistor = persistStore(store)
export default { store, persistor }

