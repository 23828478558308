import React, { useState, useEffect, useRef, useMemo } from 'react';
import '../Component/BillInvoice.css';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Select from '@mui/material/Select';
import { Typography, DialogTitle, DialogContent, DialogActions, IconButton, FormHelperText } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { Grid } from '@mui/material';
import { Checkbox, ListItemText } from '@mui/material';
import TextField from '@mui/material/TextField';
import { FaEdit, FaEye, FaTimes } from 'react-icons/fa';
// import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import billlogo from '../../../media/img/WhatsApp Image 2024-08-23 at 12.32.17 PM 1.png';
// import top100Films from './top100Films';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import PreviewIcon from '@mui/icons-material/Preview';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import html2pdf from 'html2pdf.js';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import Modal from '@mui/material/Modal';
import Snackbar from '../../../common/snackbar/components/snackbar';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { storage } from '../../../auth/constant';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
};

function BillInvoice(props) {
    // const [name, setName] = useState('');
    const [dob, setDob] = useState('');
    const [time, setTime] = useState('');
    // const [price, setPrice] = useState('');
    const [number, setNumber] = useState('');
    const [productgst, setProductGst] = useState(0);
    const [data, SetAllData] = useState(null);
    const customers = props.client.view_all_client;
    // const handleDownloadPDF = () => {
    //     // Select the content you want to convert to PDF
    //     const element = document.getElementById('invoice-content');

    //     // Define options for html2pdf
    //     const options = {
    //         margin: 1,
    //         filename: 'invoice.pdf',
    //         image: { type: 'jpeg', quality: 0.98 },
    //         html2canvas: { scale: 2 },
    //         jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    //     };

    //     // Generate the PDF
    //     html2pdf().from(element).set(options).save();
    // };
    const handleDownloadPDF = async () => {
        const invoiceContent = document.getElementById("invoice-content");
    
        // Updated watermark text and color
        const watermarkText = "PAID";
    
        // Convert the HTML content to canvas
        const canvas = await html2canvas(invoiceContent, {
            scale: 2,
            backgroundColor: "#ffffff",
            useCORS: true, // Allows loading images across domains
        });
        const imgData = canvas.toDataURL("image/png");
    
        // Create PDF and add watermark
        const pdf = new jsPDF("p", "mm", "a4");
        pdf.addImage(imgData, "PNG", 10, 10, 190, 0);
        pdf.setFontSize(40);
        pdf.setTextColor(255, 0, 0); // Red color for the watermark text
        pdf.text(watermarkText, 35, 60, { angle: 45, opacity: 0.1 }); // Watermark text "PAID"
    
        // Save the PDF
        pdf.save("Invoice_Hair_DESTRITY.pdf");
    };
    
    const handleSharePDF = () => {
        const element = document.getElementById('invoice-content');
    
        // Add "PAID" watermark to the element before generating the PDF
        const watermark = document.createElement('div');
        watermark.innerText = 'PAID';
        watermark.style.position = 'absolute';
        watermark.style.top = '50%';
        watermark.style.left = '50%';
        watermark.style.transform = 'translate(-50%, -50%) rotate(-30deg)';
        watermark.style.fontSize = '80px';
        watermark.style.color = 'red';
        watermark.style.opacity = '0.1';
        watermark.style.pointerEvents = 'none';
        element.style.position = 'relative'; // Make sure the container is positioned
    
        // Append watermark to the element temporarily for PDF generation
        element.appendChild(watermark);
    
        const options = {
            margin: 1,
            filename: 'invoice.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
            pagebreak: { mode: ['css', 'legacy'] }
        };
    
        // Generate PDF and output as a blob
        html2pdf().from(element).set(options).output('blob').then(blob => {
            const fileName = `invoices/invoice_${Date.now()}.pdf`; // Unique file name for upload
            const storageRef = ref(storage, fileName); // Firebase Storage reference
    
            // Start upload
            const uploadTask = uploadBytesResumable(storageRef, blob);
    
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    // Track progress (optional)
                },
                (error) => {
                    console.error('Error uploading PDF:', error);
                    alert("PDF could not be uploaded");
                },
                () => {
                    // Get download URL after upload
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        const whatsappLink = `https://api.whatsapp.com/send?phone=${data.customerNumber}&text=I%20have%20generated%20an%20invoice%20PDF.%20Please%20download%20it%20from%20this%20link:%20${encodeURIComponent(url)}`;
                        window.open(whatsappLink, '_blank');  // Open WhatsApp with link
                    }).catch((error) => {
                        console.error('Error getting download URL:', error);
                        alert("Error retrieving download URL");
                    });
                }
            );
    
            // Remove watermark after PDF generation
            element.removeChild(watermark);
        });
    };
    
    
    const handlePrint = () => {
        const printContent = document.getElementById('invoice-content').innerHTML;
        const printWindow = window.open('', '', 'width=800,height=600');
    
        printWindow.document.write(`
            <html>
                <head>
                    <title>Invoice</title>
                    <style>
                        /* Shared styling for consistency */
                        body { font-family: Arial, sans-serif; margin: 10px; padding: 20px; position: relative; }
                        .invoice-container { margin-top: 30px;border:1px solid black }
                        .header { text-align: left; margin-bottom: 20px; }
                        h1, h2 { color: #333; }
                        
                        /* Table styling */
                        table { width: 100%; border-collapse: collapse; margin-top: 10px; }
                        th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                        th { background-color: #f4f4f4; font-weight: bold; }
                        .total-section { text-align: right; margin-top: 20px; font-weight: bold; }
                        .preview-line { margin: 10px 0; }
                        .buttons { margin-top: 20px; }
                        .company-info, .bill-to { text-align: left; margin-bottom: 10px; }
                        
                        /* Logo size adjustment */
                        .company-logo { width: 50px; height: 50px; } /* Adjust as needed */
                        
                        /* Watermark styling */
                        .watermark {
                            position: fixed;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            opacity: 0.1;
                            font-size: 80px;
                            color: red;
                            transform: rotate(-30deg);
                            z-index: -1; /* Ensures watermark is behind content */
                            pointer-events: none;
                            user-select: none;
                        }
                    </style>
                </head>
                <body>
                    <div class="watermark">PAID</div> <!-- Watermark element -->
                    ${printContent}
                    <script>
                        window.onload = function() {
                            window.print();
                            window.close();
                        };
                    </script>
                </body>
            </html>
        `);
    
        printWindow.document.close();
        printWindow.focus();
    };
    
    
    const handleDownloadPDFproduct = () => {
        // Select the content you want to convert to PDF
        const element = document.getElementById('invoice-content');

        // Define options for html2pdf
        const options = {
            margin: 1,
            filename: 'invoice.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };

        // Generate the PDF
        html2pdf().from(element).set(options).save();
    };
    const handleSharePDFproduct = () => {
        const element = document.getElementById('invoice-content');

        const options = {
            margin: 1,
            filename: 'invoice.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };

        // Generate PDF and output as a blob
        html2pdf().from(element).set(options).output('blob').then(blob => {
            const fileName = `invoices/invoice_${Date.now()}.pdf`; // Unique file name for upload
            const storageRef = ref(storage, fileName); // Create a reference to the file in Firebase Storage

            // Start the upload process
            const uploadTask = uploadBytesResumable(storageRef, blob);

            // Monitor the upload progress and handle success/error
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    // Optionally, you can track the upload progress here
                    // const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    // console.log('Upload is ' + progress + '% done');
                },
                (error) => {
                    console.error('Error uploading PDF:', error);
                    alert("PDF Could Not Be Uploaded");
                },
                () => {
                    // Once the upload is complete, get the download URL
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        console.log('File available at', url);
                        const phoneNumber = '6294101503'; // Update this to the correct phone number
                        const whatsappLink = `https://api.whatsapp.com/send?phone=${selectedData.customerNumber}&text=I%20have%20generated%20an%20invoice%20PDF.%20Please%20download%20it%20from%20this%20link:%20${encodeURIComponent(url)}`;

                        // Open the WhatsApp share dialog
                        window.open(whatsappLink, '_blank');
                    }).catch((error) => {
                        console.error('Error getting download URL:', error);
                        alert("Error retrieving download URL");
                    });
                }
            );
        });
    };
    const handleSharePDFproductnew = () => {
        const element = document.getElementById('invoice-content');

        const options = {
            margin: 1,
            filename: 'invoice.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };

        // Generate PDF and output as a blob
        html2pdf().from(element).set(options).output('blob').then(blob => {
            const fileName = `invoices/invoice_${Date.now()}.pdf`; // Unique file name for upload
            const storageRef = ref(storage, fileName); // Create a reference to the file in Firebase Storage

            // Start the upload process
            const uploadTask = uploadBytesResumable(storageRef, blob);

            // Monitor the upload progress and handle success/error
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    // Optionally, you can track the upload progress here
                    // const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    // console.log('Upload is ' + progress + '% done');
                },
                (error) => {
                    console.error('Error uploading PDF:', error);
                    alert("PDF Could Not Be Uploaded");
                },
                () => {
                    // Once the upload is complete, get the download URL
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        console.log('File available at', url);
                        const phoneNumber = '6294101503'; // Update this to the correct phone number
                        const whatsappLink = `https://api.whatsapp.com/send?phone=${selectedDatacraer.customerNumber}&text=I%20have%20generated%20an%20invoice%20PDF.%20Please%20download%20it%20from%20this%20link:%20${encodeURIComponent(url)}`;

                        // Open the WhatsApp share dialog
                        window.open(whatsappLink, '_blank');
                    }).catch((error) => {
                        console.error('Error getting download URL:', error);
                        alert("Error retrieving download URL");
                    });
                }
            );
        });
    };
    const handlePrintproduct = () => {
        const printContent = document.getElementById('invoice-content').innerHTML;
        const printWindow = window.open('', '', 'width=800,height=600');

        printWindow.document.write(`
            <html>
                <head>
                    <title>Invoice</title>
                    <style>
                       /* You can add your styles here */
                    body { font-family: Arial, sans-serif; }
                    .invoice-container { margin-top: 30px; }
                    .header { text-align: left; }
                    table { width: 100%; border-collapse: collapse; }
                    table, th, td { border: 1px solid black; padding: 8px; text-align: left; }
                    .total-section { margin-top: 20px; text-align: right; }
           
                    </style>
                </head>
                <body>
                    ${printContent}
                    <script>
                        window.onload = function() {
                            window.print();
                            window.close();
                        };
                    </script>
                </body>
            </html>
        `);

        printWindow.document.close();
        printWindow.focus();
    };



    const handleCustomerSelect = (event, newValue) => {
        if (newValue) {
            // Use the name to find the customer
            const foundCustomer = customers.find(customer => customer.name === newValue.name);
            console.log(foundCustomer);

            if (foundCustomer) {
                // If a customer is found, set the appropriate states
                setSelectedCustomer(foundCustomer.customerNumber); // Keep track of selected customer number
                setName(foundCustomer.name); // Set the name from the selected customer
                setDob(new Date(foundCustomer.dob).toISOString().substr(0, 10)); // Set date of birth
            } else {
                // If no customer is found, reset as needed
                setSelectedCustomer(null);
                setName(''); // Optionally clear name
                setDob(''); // Optionally clear date of birth
            }
        } else {
            // Handle case when newValue is null (no selection or cleared input)
            setSelectedCustomer(null); // Clear the selected customer
            setName(''); // Clear the name
            setDob(''); // Clear the date of birth
        }
    };

    const handleCustomerNameSelect = (event, newValue) => {
        if (newValue) {
            const foundCustomer = customers.find(customer => customer.customerNumber === newValue.customerNumber);
            console.log(foundCustomer)
            if (foundCustomer) {
                setSelectedCustomer(foundCustomer.customerNumber);
                // console.log(`New customer number entered: ${foundCustomer}`);
                setName(foundCustomer.name);
                setDob(new Date(foundCustomer.dob).toISOString().substr(0, 10));
            } else {
                // Handle case when customer is not found
                // console.log(`New customer number entered: ${newValue.customerNumber}`);
                setSelectedCustomer({ customerNumber: newValue.customerNumber });
                setName(''); // Optionally set a placeholder or default name
                setDob('');  // Optionally set a placeholder or default DOB
            }
        } else {
            setSelectedCustomer(null);
            setName('');
            setDob('');
        }
    };

    const [client, setclient] = React.useState('');

    const handleChangeclient = (event) => {
        setclient(event.target.value);
    };

    const [formData, setFormData] = useState({
        client: '',
        name: '',
        number: '',
        service: '',
        employee: '',
        date: '',
        time: '',
        dob: '',
        message: '',
    });

    const handleChangedata = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
    };
    const [selectedItem, setSelectedItem] = useState('');

    const handleSelectChange = (event) => {
        setSelectedItem(event.target.value);
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
        setFinalPrice("")
        setName("");
        setNumber("");
        setproduct("");
        setCategory("");
        setService([]);
        setdiscount(0);
        setPrice(0);
        setServiceGst(0);
        setSelectedCustomer(null);
        props.clearData([])
        setIsManualFinalPrice(true)
    };
    const [select, setselect] = React.useState('');
    const [date, setDate] = React.useState(new Date());  // Initialize with current date

    const handleChange = (event) => {
        setselect(event.target.value);
    };
    // const [service, setservice] = React.useState('');
    // const handleChangetwo = (event) => {
    //     setservice(event.target.value);
    // };
    const [activeTable, setActiveTable] = useState('product');

    const [services, setservices] = useState("");
    const [discount, setdiscount] = useState(0);
    const [billeditopen, setbilleditopen] = useState(false);
    const billhandleopen = () => {
        setbilleditopen(true);
    }
    const billhandleclose = () => {
        setbilleditopen(false);
    }
    const getUniqueServicesWithPrices = () => {
        const servicePriceMap = {};

        // Iterate over each item in the view_all_service array
        props.viewallservices.view_all_service.forEach((item) => {
            // Get the category from the current item
            const category = item.category;

            // Iterate over the sub_category array
            // item.sub_category.forEach((subCat) => {
            //     // Iterate over the services array within each sub-category
            //     subCat.services.forEach((service) => {
            //         // For each service, map its name to a combination of category and price
            //         service.name.forEach((serviceName) => {
            //             servicePriceMap[serviceName] = {
            //                 category: category,
            //                 price: service.price // Use the price from the service object
            //             };
            //         });
            //     });
            // });
        });

        return servicePriceMap;
    };


    const servicePriceMap = getUniqueServicesWithPrices(); // Get service-price map
    const uniqueServices = Object.keys(servicePriceMap); // Extract the unique services
    console.log(servicePriceMap)




    const getUniqueProductsWithPrices = () => {
        const productPriceMap = {};
        // Assuming `view_all_products` contains an array of product items
        props.viewallservices.view_all_Products.forEach((item) => {
            item.product.forEach((productName) => {
                productPriceMap[productName] = item.price; // Map product name to its price
            });
        });
        return productPriceMap;
    };
    const productPriceMap = getUniqueProductsWithPrices(); // Get product-price map
    const uniqueProducts = Object.keys(productPriceMap); // Extract the unique product names

    const handleProductChange = (event, selectedProducts) => {
        // Calculate the total price based on the selected products
        const totalPrice = selectedProducts.reduce((acc, selectedProduct) => {
            return acc + (productPriceMap[selectedProduct] || 0); // Add product price or 0 if not found
        }, 0);

        setPrice(totalPrice); // Update the price based on the selected products

        // Convert array to string and store in the state
        setproduct(selectedProducts.join(', ')); // Store as a comma-separated string
    };



    const [service, setService] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [productnumber, setProductNumber] = useState("");
    const [customerName, setCustomerName] = useState('');
    const [servicegst, setServiceGst] = useState(18);

    const suggestionsRef = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                suggestionsRef.current &&
                !suggestionsRef.current.contains(event.target) &&
                inputRef.current &&
                !inputRef.current.contains(event.target)
            ) {
                setShowSuggestions(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setShowSuggestions(true);
    };

    const handleSelect = (customer) => {
        const selected = props.client.view_all_client.find(row => row.customerNumber === customer);
        if (selected) {
            setSelectedCustomer(selected.customerNumber);
            setSearchTerm(selected.customerNumber); // Update input with selected customer
            setCustomerName(selected.name); // Update customer name
        }
        setShowSuggestions(false); // Hide suggestions
    };

    const filteredCustomers = Array.isArray(props.client.view_all_client)
        ? props.client.view_all_client.filter((row) =>
            row.customerNumber.toString().includes(searchTerm)
        )
        : [];
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
    const handleCloseModalpreview = () => {
        setIsModalOpen(false);
        setSearchTerm('');
        setCustomerName('');
        setService([]);
        setdiscount('');
    };
    const handleOpenPreviewModal = () => {
        setIsPreviewModalOpen(true);
    };

    const handleClosePreviewModal = () => {
        setIsPreviewModalOpen(false);
    };
    // prodcut modal
    const [previewproduct, setpreviewproduct] = useState(false);
    const handlepreviewproductopen = () => {
        setpreviewproduct(true)
    }
    const previewproductclose = () => {
        setpreviewproduct(false)
    }
    const [productmodalopen, setproductmodalopen] = useState(false);
    const producthandleopen = () => {
        setproductmodalopen(true)
    }
    const producthandleclose = () => {
        setproductmodalopen(false);
    }

    const [productinputvalue, setproductinputvalue] = useState('');
    const handleInputChange = (e) => {
        const value = e.target.value;
        setproductinputvalue(value);
    };

    const { view_all_Products } = props.viewallservices;

    const options = Array.isArray(view_all_Products) ? view_all_Products.map(row => row.product) : [];

    const [billInvoiceModalProduct, setBillInvoiceModalProduct] = useState(false);

    const openModal = () => {
        setBillInvoiceModalProduct(true);
    };

    const closeModal = () => {
        setBillInvoiceModalProduct(false);
    };
    // customernumberwise

    const [selectedCustomernu, setSelectedCustomernu] = useState(null);

    const handleSelectChangetwo = (e) => {
        const customerNumber = e.target.value;


        const customerData = props.billinvoice.get_all_productinvoice.find(
            (row) => row.customerNumber === customerNumber
        );


        setSelectedCustomernu(customerData);
    };
    useEffect(() => {
        props.View_All_BillInvoice(props.login.token);
        props.View_All_Servicesalon(props.login.token);
        props.View_All_Products(props.login.token);
        props.viewAllClient(props.login.token);
        props.Get_all_invoice_product(props.login.token);
        props.Get_all_invoice_Gift(props.login.token);
        return () => { };
    }, []);
    const [product, setproduct] = useState('');
    // const [customerNumber, setcustomerNumber] = useState("");
    const [open, setOpen] = useState(false);
    const [selectedData, setSelectedData] = useState(null);


    const handleClickOpen = (row) => {
        setSelectedData(row);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedData(null);
    };
    const [giftmodalopen, setgiftmodalopen] = useState(false);

    const gifthandleopen = () => {
        setgiftmodalopen(true);
    }
    const giftmodalclose = () => {
        setgiftmodalopen(false)
    }
    const [name, setName] = useState('');
    const [customerNumber, setCustomerNumber] = useState('');
    const [giftName, setGiftName] = useState('');
    const [packageDetails, setPackageDetails] = useState('');
    const [expDate, setExpDate] = useState('');
    const [couponCode, setCouponCode] = useState('');
    const [price, setPrice] = useState('');
    const [appointmentid, setAppointmentId] = useState([]);

    // Make sure appointmentIds is an array of strings, like ["APP-hsNrsRCGF"]
    useEffect(() => {
        if (Array.isArray(props.billinvoice.get_all_price_by_number?.appointmentIds)) {
            setAppointmentId(props.billinvoice.get_all_price_by_number.appointmentIds);
        }
    }, [props.billinvoice.get_all_price_by_number]);

    console.log(JSON.stringify(appointmentid)); // This should log ["APP-hsNrsRCGF"]


    const [prices, setPrices] = useState(props.billinvoice.get_all_price_by_number?.totalPriceWithGST || 0);
    const [finalPrices, setFinalPrice] = useState(prices);
    // console.log(props.billinvoice.get_all_price_by_number.totalPriceWithGST)

    const [isManualFinalPrice, setIsManualFinalPrice] = useState(false);

    useEffect(() => {
        if (selectedCustomer && props.billinvoice.get_all_price_by_number && discount !== undefined) {
            calculateFinalPrice();
        }
    }, [props.billinvoice.get_all_price_by_number, selectedCustomer, discount]);


    const handlePriceChange = (event) => {
        setPrices(event.target.value);
        setIsManualFinalPrice(false); // reset manual entry when base price changes
    };


    const handleDiscountChange = (event) => {
        // const discountValue = parseFloat(event.target.value);

        // if (!isNaN(discountValue)) {
        //     setdiscount(discountValue); // Update discount as a number
        // } else {
        //     setdiscount(0); // Set to 0 if invalid value
        // }
        setdiscount(event.target.value)
        // setFinalPrice(prices - (prices * (discountValue / 100)));
        // Reset manual final price when discount changes
        // setIsManualFinalPrice(false);
    };


    const handleFinalPriceChange = (event) => {
        setFinalPrice(event.target.value);
        setIsManualFinalPrice(true); // mark final price as manually entered
    };






    const [totalprice, SetTotalPrice] = useState(0);

    const handleSave = async () => {
        try {
            // Call the API or the add function and wait for the result
            await props.Add_GiftInvoice(
                name,
                customerNumber,
                giftName,
                packageDetails,
                expDate,
                couponCode,
                price,
                props.login.token
            );

            setgiftmodalopen(false);
        } catch (error) {
            // Handle error if the API call fails
            console.error("Error adding gift invoice:", error);
        }
    };
    const [opengiftinvoice, setopengiftinvoice] = useState(false);
    const [opennew, setOpennew] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const handleViewClick = (row) => {
        setSelectedRow(row);
        setopengiftinvoice(true);
    };

    const [selectedDatacraer, setSelectedDatacreate] = React.useState({
        name: '',
        customerNumber: '',
        product: '',
        discount: 0,
        price: 0,
    });

    const handleCloseview = () => {
        setopengiftinvoice(false);
        setSelectedRow(null);
    };
    const handleAddProductInvoice = () => {
        // Call the function and perform necessary actions
        props.Add_ProductInvoice(name, number, product, discount, (price - (price * (discount / 100))).toFixed(2), props.login.token);

        // After executing the function, trigger the print
        handlePrintproduct();
    };
    console.log(name, selectedCustomer, service, discount, ((price - (price * (discount / 100))) + (price - (price * (discount / 100))) * (servicegst / 100)), servicegst, props.login.token)
    const [category, setCategory] = useState('');
    // Change to null for single selection
    const [allservicebycategory, setAllServiceByCategory] = useState([]);
    const [errors, setErrors] = useState({ category: '', service: '' });

    // Use useMemo to compute unique service names

    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
        setService([]); // Reset selected service when category changes
    };
    const handleServiceChange = (event, newValue) => {
        setService(newValue);
        // setSelectedServices(newValue); // Update selected services

        // Calculate the total price based on selected services
        const totalPrice = newValue.reduce((total, serviceName) => {
            // Find the service that includes the service name
            const foundService = allservicebycategory.find(service => service.name.includes(serviceName));
            return total + (foundService ? foundService.price : 0);
        }, 0);

        console.log('Total Price:', totalPrice); // Debug log
        setPrice(totalPrice); // Update total price
    };


    const finalPrice = service.reduce((total, serviceName) => {
        const foundService = allservicebycategory.find(service => service.name === serviceName);
        const servicePrice = foundService ? foundService.price : 0;
        return total + (servicePrice - (servicePrice * (discount / 100))) + (servicePrice - (servicePrice * (discount / 100))) * (servicegst / 100);
    }, 0);
    console.log(finalPrice)
    const handleFormSubmit = () => {
        let errors = {};

        // Validation
        if (!name) errors.name = 'Name is required';
        if (!selectedCustomer) errors.selectedCustomer = 'Customer is required';

        // Check discount is a valid number


        // Set errors and handle form submission
        setErrors(errors);

        if (Object.keys(errors).length === 0) {
            setIsModalOpen(false);  // Close the modal on successful validation

            // Console log to verify all variables
            // console.log(
            //     name,
            //     selectedCustomer,
            //     props.billinvoice.get_all_price_by_number?.serviceNamesWithPrices?.map(service => service.serviceName),
            //     discount,
            //     finalPrices,
            //     props.login.token
            // );
            const appointmentData = {
                appointmentIds: appointmentid,
            };
            // Proceed with submitting the form
            props.Add_Billinvoice(
                name,
                selectedCustomer,
                props.billinvoice.get_all_price_by_number.serviceNamesWithPrices, // Default to empty array if undefined
                finalPrices,
                props.login.token
            );


            props.UpdateAppoimentSatus(
                props.login.token,
                appointmentid // Directly pass the array
            );

            console.log(JSON.stringify(appointmentid))
            // console.log(     props.login.token,
            //          appointmentid)

            // Reset form fields after successful submission
            setName("");
            setNumber("");
            setproduct("");
            setCategory("");
            setService([]);
            setdiscount(0);
            setPrice(0);
            setServiceGst(0);
            setSelectedCustomer(null);
            setFinalPrice("");
            props.clearData([]);  // Clear any additional data
        }
    };




    const calculateFinalPrice = () => {
        const { totalPrice = 0, totalPriceWithGST = 0 } = props.billinvoice.get_all_price_by_number || {};

        // Step 1: Calculate discounted price
        const discountedPrice = totalPrice - (totalPrice * (discount / 100));

        // Step 2: Add the GST amount (difference between totalPriceWithGST and totalPrice)
        const gstAmount = totalPriceWithGST - totalPrice;
        const finalPriceWithGST = discountedPrice + gstAmount;

        setFinalPrice(finalPriceWithGST);
    };

    console.log(data)
    return (
        <div className='surround'>
            <div>
                <div className='appointment-text'>
                    <h1>
                        Bill / Invoice
                    </h1>
                    <div className='appointment-button'>
                        {activeTable === 'service' && (
                            <Button
                                className="bill_button"
                                // onClick={producthandleopen}
                                onClick={openModal}
                                style={{ backgroundColor: "#6A8C9F", color: "#fff" }}
                            >
                                Add Bill / Product
                            </Button>
                        )}
                        {activeTable === 'product' && (
                            <Button
                                className="bill_button"
                                onClick={handleOpenModal}
                                style={{ backgroundColor: "#6A8C9F", color: "#fff" }}
                            >
                                Add Bill / Service
                            </Button>
                        )}
                        {/* {activeTable === 'gift' && (
                            <Button
                                className="bill_button"
                                onClick={gifthandleopen}
                                style={{ backgroundColor: "#6A8C9F", color: "#fff" }}
                            >
                                Add Bill / Gift
                            </Button>
                        )} */}
                    </div>
                </div>
            </div>
            <div className='appointment-text'>
                <div className='btn-grp' style={{ margin: "0px" }}>
                    <ButtonGroup variant="" >
                        <Button
                            className={activeTable === 'product' ? 'active2' : ''}
                            onClick={() => setActiveTable('product')}
                        >
                            Service
                        </Button>
                        <Button
                            className={activeTable === 'service' ? 'active2' : ''}
                            onClick={() => {
                                setActiveTable('service')
                                // setShowMessage(true);
                            }}
                        >
                            product
                        </Button>
                        <Button
                            className={activeTable === 'gift' ? 'active2' : ''}
                            onClick={() => {
                                // setActiveTable('service')
                                // setShowMessage(true)
                                setActiveTable('gift');
                            }}
                        >
                            GifT Bill
                        </Button>

                    </ButtonGroup>
                </div>
            </div>
            {activeTable === 'product' && (
                <div className='appointment-main'>
                    <div className="table-container">
                        <table className="styled-table">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th style={{ whiteSpace: "nowrap" }}>In No</th>
                                    <th>Name</th>

                                    <th>Phone</th>
                                    <th>Service</th>
                                    <th>Tax</th>
                                    <th>Discount</th>
                                    <th>Price</th>

                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(props.billinvoice.view_all_billinvoice) && props.billinvoice.view_all_billinvoice.map((row, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{row.invoiceId}</td>
                                        <td>{row.name}</td>

                                        <td>{row.customerNumber}</td>
                                        <td style={{ width: "150px" }}>
                                            {Array.isArray(row.serviceNamesWithPrices) ? (
                                                row.serviceNamesWithPrices.map((service, i) => (
                                                    <Button
                                                        key={i}
                                                        variant="outlined"
                                                        size="small"
                                                        sx={{
                                                            margin: '2px',
                                                            borderColor: '#6A8C9F',
                                                            color: '#6A8C9F',
                                                            '&:hover': {
                                                                borderColor: '#004D40',
                                                                color: '#004D40',
                                                            },
                                                        }}
                                                    >
                                                        {service.serviceName}
                                                    </Button>
                                                ))
                                            ) : (
                                                row.service
                                            )}
                                        </td>
                                        <td>₹{row.taxAmount}</td>
                                        <td>₹{row.discount}</td>
                                        <td>₹{row.finalPrice}</td>

                                        <td>
                                            {/* <IconButton onClick={()=>{
                                             setpreviewproduct(true)
                                         }}>
                                         <ArrowCircleDownIcon  className="action-icon" style={{ color: "crimson"}} />
                                         </IconButton> */}
                                            <IconButton onClick={() => {
                                                setpreviewproduct(true);
                                                SetAllData(row)
                                            }}>


                                                <PreviewIcon className="action-icon" style={{ color: 'purple' }} />
                                            </IconButton>
                                            {/* <IconButton onClick={()=>{
                                             setpreviewproduct(true)

                                         }}>
                                         <WhatsAppIcon  className="action-icon" style={{ color: 'green' }} />
                                        </IconButton> */}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
            {activeTable === 'service' && (
                <div className='appointment-main'>
                    <div className="table-container">
                        <table className="styled-table">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th style={{ whiteSpace: "nowrap" }}>In No</th>
                                    <th>Name</th>

                                    <th>Phone</th>
                                    <th>Product</th>
                                    <th>Price</th>
                                    <th>GST</th>
                                    <th>Discount</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Array.isArray(props.billinvoice.get_all_productinvoice) && props.billinvoice.get_all_productinvoice.map((row, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{row._id}</td>
                                            <td>{row.name}</td>
                                            <td>{row.customerNumber}</td>
                                            <td className="product-box">{row.product}</td>
                                            <td>{row.price}</td>
                                            <td>{row.gst} %</td>
                                            <td>{row.discount}%</td>
                                            <td>

                                                <FaEye color='#6A8C9F' onClick={() => handleClickOpen(row)} />

                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
            {/* gift table */}
            {activeTable === 'gift' && (
                <div className='appointment-main'>
                    <div className="table-container">
                        <table className="styled-table">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Name</th>
                                    <th>packageDetails</th>
                                    <th>Gift Name</th>
                                    <th>Phone</th>
                                    <th>couponCode</th>
                                    <th>Price</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Array.isArray(props.billinvoice.get_all_giftinvoice) && props.billinvoice.get_all_giftinvoice.map((row, index) => (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{row.name}</td>
                                            <td>{row.packageDetails}</td>
                                            <td>{row.giftName}</td>
                                            <td>{row.customerNumber}</td>
                                            <td>{row.couponCode}</td>
                                            <td>{row.price}</td>
                                            <td style={{ display: 'flex', alignItems: 'center', justifyContent: "center", margin: "15px" }}>
                                                {/* <FaEdit color='#008080'/> */}
                                                <FaEye color='#008080' onClick={() => handleViewClick(row)} style={{ cursor: 'pointer' }} />
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            )}

            {/* modal */}



            {isModalOpen && (
                <Dialog open={isModalOpen} onClose={handleCloseModal} maxWidth="sm">
                    <div className="appointment-overlay">
                        <div className="appointment-content">
                            <button className="appointment-close" onClick={handleCloseModal}>
                                &times;
                            </button>
                            <h2>Add Invoice Service</h2>
                            <hr className='appointment-line' />
                            <div className="form-container">
                                <div className="form-row" style={{ display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
                                    <div style={{ flex: 1 }}>
                                        <Autocomplete
                                            fullWidth
                                            freeSolo
                                            options={customers}
                                            getOptionLabel={(option) => {
                                                // Display both name and customer number if both exist
                                                if (option.name && option.customerNumber) {
                                                    return `${option.name} - ${option.customerNumber}`;
                                                }
                                                return option.name || '';
                                            }}
                                            filterOptions={(options, { inputValue }) => {
                                                const lowercasedInput = inputValue.toLowerCase();
                                                return options.filter((option) =>
                                                    option.name.toLowerCase().includes(lowercasedInput)
                                                );
                                            }}
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    // If a customer is selected, update the state with both number and name
                                                    setSelectedCustomer(newValue.customerNumber);
                                                    setName(newValue.name);
                                                    setDob(newValue.dob || ''); // Assuming there's a dob property
                                                    props.getAllAppoinmentByNumber(props.login.token, newValue.customerNumber);
                                                    setIsManualFinalPrice(true);

                                                    // Recalculate the final price for the new customer data
                                                    calculateFinalPrice();
                                                } else {
                                                    // If selection is cleared, reset the fields
                                                    setFinalPrice(""); // Reset final price if selection is cleared
                                                    setSelectedCustomer(null);
                                                    setName('');
                                                    setDob('');
                                                }
                                            }}

                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    label="Search Customer Name"
                                                    placeholder="Enter customer Name"
                                                    size="small"
                                                    value={number ? `${name} - ${number}` : ''}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        setNumber(value);
                                                        if (!value) {
                                                            setSelectedCustomer(null);
                                                            setName('');
                                                            setDob('');
                                                        }
                                                    }}
                                                    error={Boolean(errors.selectedCustomer)}
                                                    helperText={errors.selectedCustomer}
                                                />
                                            )}
                                        />






                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <TextField
                                            value={selectedCustomer || ''}
                                            size="small"
                                            label="Number"
                                            fullWidth
                                            slotProps={{
                                                input: {
                                                    readOnly: true,
                                                },
                                            }}
                                            disabled
                                            error={Boolean(errors.number)}
                                            helperText={errors.number}
                                        />
                                    </div>
                                </div>

                                <div className="form-row" style={{ display: 'flex', justifyContent: 'space-between', gap: '20px', marginTop: '30px' }}>
                                    <Grid container spacing={2}>

                                        <Grid item xs={12} sm={12}>
                                            <FormControl fullWidth error={Boolean(errors.service)}>
                                                <Autocomplete
                                                    multiple
                                                    // disabled
                                                    readOnly
                                                    options={props.billinvoice.get_all_price_by_number?.serviceNamesWithPrices?.map(service => service.serviceName) || []}
                                                    getOptionLabel={(option) => option}
                                                    // value={service}
                                                    value={props.billinvoice.get_all_price_by_number?.serviceNamesWithPrices?.map(service => service.serviceName) || []}
                                                    onChange={handleServiceChange}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            label="Services"
                                                            placeholder="Search for services"
                                                        />
                                                    )}
                                                    noOptionsText="No services found"
                                                />
                                                <FormHelperText>
                                                    {errors.service}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </div>



                                <div className="form-row" style={{ display: 'flex', justifyContent: 'space-between', gap: '20px', marginTop: '20px' }}>

                                    <div style={{ flex: 1 }}>
                                        <TextField
                                            variant="outlined"
                                            label="Final Price (After Discount and GST)"
                                            value={finalPrices}
                                            onChange={handleFinalPriceChange}
                                            size="small"
                                            fullWidth
                                        />
                                    </div>
                                </div>

                                <div className="bill-invoice-button" style={{ marginTop: '20px', textAlign: 'right' }}>
                                    <button style={{ background: "#fff", border: "1px solid #6A8C9F", margin: "20px 15px", color: "#6A8C9F", fontSize: "17px", padding: "10px 60px", borderRadius: "10px" }} onClick={handleCloseModal}>Cancel</button>
                                    <button type="submit" className='bill-invoice-preview' onClick={handleFormSubmit}>Submit Bill</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            )}


            {/* bill edit popup */}
            {
                isPreviewModalOpen && (
                    <Dialog open={isPreviewModalOpen} onClose={handleClosePreviewModal} maxWidth="sm" fullWidth>
                        <div className="preview-overlay">
                            <div className="preview-content">
                                <h2 style={{ fontSize: "20px", margin: "10px 10px" }}>Preview Invoice</h2>
                                <hr className='preview-line' />
                                <div className="invoice-container" style={{ marginBottom: "30px", marginTop: "30px" }}>
                                    <div className="header">
                                        <div className="company-info" style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px" }}>
                                            <div>
                                                <img src={billlogo} style={{ width: "70px", height: "70px", position: "relative", top: "-20px" }} />
                                            </div>
                                            <div>
                                                <h1 style={{ fontSize: "15px" }}>Hair Destrity</h1>
                                                <p style={{ fontSize: "13px" }}>www.website.com</p>
                                                <p style={{ fontSize: "13px" }}>hello@email.com</p>
                                                <p style={{ fontSize: "13px" }}>+91 00000 00000</p>
                                            </div>
                                        </div>
                                        <div className="invoice-details">
                                            <p style={{ fontSize: "13px" }}>Invoice Number: {data.invoiceId}</p>
                                            <p> Date: {date ? date.toLocaleDateString() : ''}</p>
                                            <p>{searchTerm}</p>
                                        </div>
                                    </div>

                                    <table className="invoice-table">
                                        <thead>
                                            <tr>
                                                <th>Item Detail</th>
                                                <th>Qty</th>
                                                <th>Rate</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Item 1 Description</td>
                                                <td>1</td>
                                                <td>3,000.00</td>
                                                <td>3,000.00</td>
                                            </tr>
                                            <tr>
                                                <td>Item 2 Description</td>
                                                <td>1</td>
                                                <td>1,500.00</td>
                                                <td>1,500.00</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div className="total-section">
                                        <p>Subtotal: 4,500.00</p>
                                        <p>Tax (10%): 450.00</p>
                                        <p>Total: 4,950.00</p>
                                    </div>
                                    <div className="buttons">
                                        <button className="cancel-btn" onClick={handleClosePreviewModal} style={{ backgroundColor: "#fff", color: "black", border: "1px solid #008080", }}>Cancel</button>
                                        <button className="save-btn">Save</button>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </Dialog>
                )
            }
            {/* add product popup */}
            {
                productmodalopen && (
                    <div className="invoice-overlay" onClick={producthandleclose}>
                        <div
                            className="invoice-container"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <h2 style={{ textAlign: "start" }}>Add Invoice Product</h2>
                            <hr className="appointment-line" />
                            {/* Start */}
                            <div>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", gap: "20px", marginBottom: "20px", marginLeft: "-23px", marginTop: "20px" }}>
                                    <div style={{ position: 'relative' }}>
                                        <div>
                                            <label style={{ position: "relative", top: "5px", left: "-60px" }}>Customer Number</label>
                                        </div>
                                        <TextField
                                            value={productnumber}
                                            onChange={(e) => {
                                                setProductNumber(e.target.value)
                                            }}
                                            // onFocus={() => setShowSuggestions(true)}
                                            // inputRef={inputRef}
                                            style={{ width: '250px', height: '36px', marginTop: "20px" }}
                                        />
                                        {showSuggestions && filteredCustomers.length > 0 && (
                                            <ul
                                                ref={suggestionsRef}
                                                style={{ position: 'absolute', top: '100%', left: 0, width: '100%', backgroundColor: '#fff', border: '1px solid #ccc', zIndex: 1, maxHeight: '200px', overflowY: 'auto' }}
                                            >
                                                {filteredCustomers.map((row) => (
                                                    <li
                                                        key={row.customerNumber}
                                                        onClick={() => handleSelect(row.customerNumber)}
                                                        style={{ padding: '8px', cursor: 'pointer' }}
                                                    >
                                                        {row.customerNumber}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                    <div style={{ marginTop: '20px' }}>
                                        <div>
                                            <label style={{ position: "relative", top: "-10px", left: "-96px" }}>Name</label>
                                        </div>
                                        <TextField
                                            value={customerName}
                                            style={{ width: '250px', height: '36px' }}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </div>
                                </div>
                                {/* select column */}
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", gap: "20px", margin: "0px auto", marginTop: "50px" }}>
                                    <div>
                                        <label style={{ position: "relative", left: "-67px", bottom: "10px" }}>Product Name</label>
                                        <Autocomplete
                                            freeSolo
                                            options={options}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    value={props.productinputvalue}
                                                    onChange={(e) => props.setproductinputvalue(e.target.value)}
                                                    style={{ width: '250px', height: '60px' }} // Adjust width and height
                                                />
                                            )}
                                        />
                                    </div>
                                    <div>
                                        <label style={{ position: "relative", left: "-90px", bottom: "10px" }}>Discount</label>
                                        <FormControl fullWidth style={{}}>
                                            {/* <InputLabel htmlFor="discount">Discount</InputLabel> */}
                                            <TextField
                                                id="discount"
                                                style={{ width: '250px', position: "relative", left: "30px" }}
                                                variant="outlined"

                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "0px auto" }}>
                                <div>
                                    <button style={{ background: "#fff", border: "1px solid blue", margin: "20px 15px ", color: "#008080", fontSize: "17px", padding: "8px 60px", borderRadius: "10px" }} onClick={producthandleclose}>Cancel</button>
                                </div>
                                <div>
                                    <button type="submit" className='bill-invoice-preview' onClick={() => {

                                    }}>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {/* preview productmodal */}
            {
                previewproduct && (
                    <Dialog open={previewproduct} onClose={previewproductclose} maxWidth="sm" fullWidth>
                        <div className="preview-overlay">
                         
                                <div className="invoice-container" id="invoice-content">
                                    <div >
                                        <div className="company-info">
                                            <img src={billlogo} className="company-logo" />
                                            <h1>Hair Destrity</h1>
                                            <p style={{fontSize:"12px"}}> 
                                                52, 4th floor community centre, NFC 110025<br />
                                                Phone no.: 9911056149<br />
                                                Email : hairdexterity0@gmail.com<br />
                                                GSTIN: 07EIYPK6319C1ZW<br />
                                                State: 07-Delhi
                                            </p>
                                        </div>
                                       
                                        <div className="invoice-details">
                                            <p>Invoice Number: {data.invoiceId}</p>
                                            <p>Date: {date ? date.toLocaleDateString() : ''}</p>
                                        </div>
                                        <div >
                                            <h3 style={{lineHeight:"0px",textAlign:"left"}}>Bill To:</h3>
                                            <div className="client-info">

                                            <p>Name: {data.name}</p>
                                               <p> Phone no.: {data.customerNumber}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <table className="invoice-table">
                                        <thead>
                                            <tr>
                                                <th>SI No</th>
                                                <th>Service Name</th>
                                                <th>Qnt</th>
                                                <th>Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.serviceNamesWithPrices.map((serviceItem, index) => (
                                                <tr key={serviceItem._id}>
                                                    <td>{index + 1}</td>
                                                    <td>{serviceItem.serviceName}</td>
                                                    <td>1</td>
                                                    <td>₹{serviceItem.price}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    <div className="total-section">
                                        <p>Sub Total: ₹{data.allServicePrice}</p>
                                        <p>Discount: ₹{data.discount}</p>
                                        <p>Discount Price: ₹{data.basePrice}</p>
                                        <p>Tax: ₹{data.taxAmount}</p>
                                        <p>Total: ₹{data.finalPrice}</p>
                                    </div>
                                </div>

                                <div className="buttons">
                                    <button className="cancel-btn" onClick={previewproductclose} style={{ backgroundColor: "#fff", color: "black", border: "1px solid #008080", }}>Close</button>
                                    <button className="cancel-btn" onClick={handleDownloadPDF} style={{ backgroundColor: "bisque", color: "black", border: "1px solid #888", }}>Download</button>
                                    <button className="cancel-btn" onClick={handleSharePDF} style={{ backgroundColor: "#fff", color: "black", border: "1px solid #008080", }}> <WhatsAppIcon className="action-icon" style={{ color: 'green' }} /></button>
                                    <button className="cancel-btn" onClick={handlePrint} style={{ backgroundColor: "#fff", color: "black", border: "1px solid #008080", }}> <LocalPrintshopIcon className="action-icon" style={{ color: "darkslateblue" }} /></button>

                                </div>

                           
                        </div>
                    </Dialog>
                )
            }
            <Dialog
                open={showMessage}
                onClose={() => {
                    setShowMessage(false)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Feature Coming Soon"}</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        We are working on it. This feature will be available in a few days.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setShowMessage(false)
                    }} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            {/* new product invoice modal */}

            <Dialog open={billInvoiceModalProduct} onClose={() => {
                setBillInvoiceModalProduct(false);
            }} maxWidth="sm" fullWidth>
                <div className="appointment-overlay">
                    <div className="appointment-content">
                        <button className="appointment-close" onClick={() => {
                            setBillInvoiceModalProduct(false);
                        }}>
                            &times;
                        </button>
                        <h2>Bill Invoice Product</h2>
                        <hr className='appointment-line' />
                        <div className="form-container">

                            <div className="form-row">
                                <div style={{ display: "flex", flexDirection: "column" }}>

                                    <div className="form-group discount-bill" style={{ marginTop: '20px' }}>
                                        {/* Name Field */}
                                        <div>
                                            <label style={{ position: "relative" }}>Name</label>
                                        </div>
                                        <TextField
                                            variant="outlined"
                                            value={name}
                                            size="small"
                                            onChange={(e) => setName(e.target.value)}
                                            sx={{ width: '450px', height: '40px' }}
                                            InputProps={{
                                                style: {
                                                    height: '50px', // Match height to the GST field
                                                },
                                            }}
                                        />

                                        {/* Customer Number Field */}
                                        <div style={{ marginTop: '20px' }}>
                                            <label style={{ position: "relative" }}>Customer Number</label>
                                        </div>
                                        <TextField
                                            variant="outlined"
                                            value={number}
                                            size="small"
                                            onChange={(e) => setNumber(e.target.value)}
                                            sx={{ width: '450px', height: '40px' }}
                                            InputProps={{
                                                style: {
                                                    height: '50px', // Match height to the GST field
                                                },
                                            }}
                                            placeholder="Add customer number"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="formrow" style={{ marginTop: "0px" }}>
                                <div className="form-group">
                                    {/* <label>Product Name</label> */}
                                    <FormControl fullWidth sx={{ width: '450px' }}>
                                        <Autocomplete
                                            multiple
                                            options={uniqueProducts}
                                            // Split string to show selected products as an array in UI, trimming spaces and filtering out empty strings
                                            value={product.split(',').map(item => item.trim()).filter(item => item)}
                                            onChange={handleProductChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Select Products"
                                                    variant="outlined"
                                                    placeholder="Products"
                                                />
                                            )}
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props}>
                                                    <Checkbox
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option}
                                                </li>
                                            )}
                                            renderTags={(selected, getTagProps) => {
                                                const maxVisibleItems = 2; // Limit the number of visible items
                                                if (selected.length > maxVisibleItems) {
                                                    return `${selected.slice(0, maxVisibleItems).join(', ')} + ${selected.length - maxVisibleItems} more`;
                                                }
                                                return selected.map((option, index) => (
                                                    <span
                                                        style={{
                                                            padding: "2px 6px",
                                                            backgroundColor: "#e0e0e0",
                                                            borderRadius: "4px",
                                                            marginRight: "5px",
                                                        }}
                                                        {...getTagProps({ index })}
                                                        key={index}
                                                    >
                                                        {option}
                                                    </span>
                                                ));
                                            }}
                                        />

                                    </FormControl>
                                </div>

                                <div className="form-group discount-bill ">
                                    <label>GST (use only number )</label>
                                    <TextField
                                        variant="outlined"
                                        value={productgst}
                                        size='small'
                                        onChange={(e) => setProductGst(e.target.value)}
                                        sx={{ width: '450px', height: '40px' }}
                                        InputProps={{
                                            style: {
                                                height: '50px',
                                            },
                                        }}
                                    />
                                </div>
                                <div className="form-group discount-bill">
                                    <label>Discount (use only numbers)</label>
                                    <TextField
                                        variant="outlined"
                                        value={discount}
                                        size='small'
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value === '' || /^[0-9\b]+$/.test(value)) {  // Only allow numbers
                                                setdiscount(value);
                                            }
                                        }}
                                        sx={{ width: '450px', height: '40px' }}
                                        InputProps={{
                                            style: {
                                                height: '50px',
                                            },
                                        }}
                                    />
                                </div>

                                <div className="form-group discount-bill">
                                    <label>Total Price (Before Discount)</label>
                                    <TextField
                                        variant="outlined"
                                        value={price} // Display the calculated total price
                                        size='small'
                                        disabled
                                        sx={{ width: '450px', height: '40px' }}
                                        InputProps={{
                                            style: {
                                                height: '50px',
                                            },
                                        }}
                                    />
                                </div>

                                <div className="form-group discount-bill">
                                    <label>Final Price (After Discount and Gst)</label>
                                    <TextField
                                        variant="outlined"
                                        value={((price - (price * (discount / 100))) + (price - (price * (discount / 100))) * (productgst / 100))}
                                        size='small'
                                        disabled
                                        sx={{ width: '450px', height: '40px' }}
                                        InputProps={{
                                            style: {
                                                height: '50px',
                                            },
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="bill-invoice-button">
                                <div>
                                    <button style={{ background: "#fff", border: "1px solid #6A8C9F", margin: "20px 15px ", color: "#6A8C9F", fontSize: "17px", padding: "10px 60px", borderRadius: "10px" }} onClick={() => {
                                        setBillInvoiceModalProduct(false);
                                    }}>Cancel</button>
                                </div>
                                <div>
                                    <button type="submit" className='bill-invoice-preview' onClick={() => {
                                        setBillInvoiceModalProduct(false);
                                        // setSelectedData(name, number, product, discount, (price - (price * (discount / 100))).toFixed(2), props.login.token);
                                        setOpennew(true);

                                        props.Add_ProductInvoice(name, number, product, discount, ((price - (price * (discount / 100))) + (price - (price * (discount / 100))) * (productgst / 100)), productgst, props.login.token);
                                        console.log(name, number, product, discount, ((price - (price * (discount / 100))) + (price - (price * (discount / 100))) * (productgst / 100)), productgst, props.login.token);
                                        setName(" ")
                                        setNumber(" ")
                                        setproduct(" ")
                                        setdiscount(0)
                                        setPrice(0)
                                        setProductGst(0)
                                        setSelectedDatacreate({
                                            name: name,
                                            customerNumber: number,
                                            product: product,
                                            discount: discount,
                                            gst: productgst,
                                            price: ((price - (price * (discount / 100))) + (price - (price * (discount / 100))) * (servicegst / 100))

                                        });

                                    }}>Create Bill</button>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </Dialog>

            {/* viewinvoice product */}
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle>Preview Product Bill Invoice</DialogTitle>
                <hr />

                {selectedData && (
                    <div >
                        <div className='Preview-invoiceproduct' id="invoice-content">
                            <div className="header">
                                <div className="company-info" style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px" }}>
                                    <div>
                                        <img src={billlogo} style={{ width: "70px", height: "70px", position: "relative", top: "-20px" }} />
                                    </div>
                                    <div >
                                        <h1 style={{ fontSize: "15px", textAlign: "left" }}>Hair Destrity</h1>
                                        <p style={{ fontSize: "13px", textAlign: "left" }}>52, 4th floor community centre, NFC 110025<br></br>
                                            Phone no.: 9911056149<br></br>
                                            Email : hairdexterity0@gmail.com<br></br>
                                            GSTIN: 07EIYPK6319C1ZW<br></br>
                                            State: 07-Delhi<br></br></p>

                                    </div>
                                </div>
                                <div style={{ marginTop: "-10px" }}>
                                    <h1 style={{ fontSize: "15px", textAlign: "left" }}>Bill To:</h1>

                                    <p style={{ fontSize: "13px", textAlign: "left" }}>
                                        Name :{selectedData.name}<br></br>
                                        Phone no.: {selectedData.customerNumber}<br></br>

                                    </p>

                                </div>
                                <div className="invoice-details">
                                    <p style={{ fontSize: "13px" }}>Invoice Number: {selectedData._id}</p>
                                    <p> Date: {date ? date.toLocaleDateString() : ''}</p>
                                    <p>{searchTerm}</p>
                                </div>
                            </div>
                            <table className="invoice-table">
                                <thead>
                                    <tr>
                                        <th>Item Detail</th>
                                        <th>Qty</th>
                                        <th>Rate</th>
                                        <th>SGST</th>
                                        <th>CGST</th>

                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{selectedData.product}</td>
                                        <td>1</td>
                                        <td>{(selectedData.price / (1 - selectedData.discount / 100) / (1 + selectedData.gst / 100)).toFixed(2)}</td>
                                        <td>{selectedData.gst / 2} %</td>
                                        <td>{selectedData.gst / 2} %</td>
                                        <td>{((selectedData.price / (1 - selectedData.discount / 100) / (1 + selectedData.gst / 100)) +
                                            ((selectedData.price / (1 - selectedData.discount / 100) / (1 + selectedData.gst / 100)) * (selectedData.gst / 100))).toFixed(2)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="total-section">
                                <p>Discount {selectedData.discount}%</p>

                                <p>Total: {selectedData.price}</p>
                            </div>
                        </div>
                        <div className="buttons" style={{ marginBottom: "20px" }}>
                            <button className="cancel-btn" onClick={handleClose} style={{ backgroundColor: "#fff", color: "black", border: "1px solid #008080", }}>Close</button>
                            <button className="cancel-btn" onClick={handleDownloadPDFproduct} style={{ backgroundColor: "bisque", color: "black", border: "1px solid #888", }}>Download</button>
                            <button className="cancel-btn" onClick={handleSharePDFproduct} style={{ backgroundColor: "#fff", color: "black", border: "1px solid #008080", }}> <WhatsAppIcon className="action-icon" style={{ color: 'green' }} /></button>
                            <button className="cancel-btn" onClick={handlePrintproduct} style={{ backgroundColor: "#fff", color: "black", border: "1px solid #008080", }}> <LocalPrintshopIcon className="action-icon" style={{ color: "darkslateblue" }} /></button>
                        </div>
                    </div>
                )}

            </Dialog>
            <Dialog open={opennew} onClose={() => {
                setOpennew(false)
            }} maxWidth="sm" fullWidth>
                <DialogTitle>Preview Product Invoice</DialogTitle>
                <hr />
                <DialogContent>

                    <div >
                        <div className='Preview-invoiceproduct' id="invoice-content">
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "10px" }}>
                                <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start", gap: "10px" }}>
                                    <img src={billlogo} style={{ width: "40px", height: "40px", objectFit: "cover" }} />
                                    <div style={{ marginTop: "-10px" }}>
                                        <h1 style={{ fontSize: "15px", textAlign: "left" }}>Hair Destrity</h1>
                                        <p style={{ fontSize: "13px", textAlign: "left" }}>52, 4th floor community centre, NFC 110025<br></br>
                                            Phone no.: 9911056149<br></br>
                                            Email : hairdexterity0@gmail.com<br></br>
                                            GSTIN: 07EIYPK6319C1ZW<br></br>
                                            State: 07-Delhi<br></br></p>

                                    </div>

                                </div>

                                <div>

                                    {/* <p style={{ fontSize: "13px" }}>Invoice Number: {data.invoiceId}</p> */}
                                    <p> Date: {date ? date.toLocaleDateString() : ''}</p> {/* Display the formatted date */}
                                </div>

                            </div>
                            <div style={{ marginTop: "-10px" }}>
                                <h1 style={{ fontSize: "15px", textAlign: "left" }}>Bill To:</h1>

                                <p style={{ fontSize: "13px", textAlign: "left" }}>
                                    Name :{selectedDatacraer.name}<br></br>
                                    Phone no.: {selectedDatacraer.customerNumber}<br></br>

                                </p>

                            </div>
                            <table className="invoice-table">
                                <thead>
                                    <tr>
                                        <th>Item Detail</th>
                                        <th>Qty</th>
                                        <th>Rate</th>
                                        <th>SGST</th>
                                        <th>CGST</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{selectedDatacraer.product}</td>
                                        <td>1</td>
                                        <td>
                                            {/* Calculate the original price before GST */}
                                            {(
                                                selectedDatacraer.price /
                                                (1 - selectedDatacraer.discount / 100)
                                            ).toFixed(2)}
                                        </td>
                                        <td>{(selectedDatacraer.gst / 2).toFixed(2)} %</td>
                                        <td>{(selectedDatacraer.gst / 2).toFixed(2)} %</td>
                                        <td>
                                            {/* Calculate the final price including GST */}
                                            {(
                                                (selectedDatacraer.price /
                                                    (1 - selectedDatacraer.discount / 100)) *
                                                (1 + selectedDatacraer.gst / 100)
                                            ).toFixed(2)}
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                            <div className="total-section">
                                <p>Discount: {selectedDatacraer.discount}%</p>
                                <p>Total: {(selectedDatacraer.price * (1 + selectedDatacraer.gst / 100)).toFixed(2)}</p>

                            </div>
                        </div>
                        <div className="buttons">
                            <button className="cancel-btn" onClick={() => {
                                setOpennew(false)
                            }} style={{ backgroundColor: "#fff", color: "black", border: "1px solid #008080", }}>Close</button>
                            <button className="cancel-btn" onClick={() => {
                                setOpennew(false)
                                handleDownloadPDFproduct()
                            }} style={{ backgroundColor: "bisque", color: "black", border: "1px solid #888", }}>Download</button>
                            <button className="cancel-btn" onClick={() => {
                                setOpennew(false)
                                handleSharePDFproductnew()
                            }} style={{ backgroundColor: "#fff", color: "black", border: "1px solid #008080", }}> <WhatsAppIcon className="action-icon" style={{ color: 'green' }} /></button>
                            <button className="cancel-btn" onClick={() => {
                                setOpennew(false)
                                handlePrintproduct()
                            }} style={{ backgroundColor: "#fff", color: "black", border: "1px solid #008080", }}> <LocalPrintshopIcon className="action-icon" style={{ color: "darkslateblue" }} /></button>
                        </div>
                    </div>

                </DialogContent>
            </Dialog>
            {/* gift add modal */}
            {/* <Dialog
                open={giftmodalopen}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    setgiftmodalopen(false);
                }}
                aria-describedby="alert-dialog-slide-description"
                fullWidth
            >
                <DialogTitle>Add Gift Invoice</DialogTitle>
                <DialogContent>
                    <div>
                        <Typography>Name</Typography>
                        <TextField
                            placeholder="Input 1"
                            variant="outlined"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            fullWidth
                        />
                        <div style={{ marginTop: "10px" }}>
                            <Typography>Number</Typography>
                            <TextField
                                placeholder="Input 1"
                                variant="outlined"
                                fullWidth
                                value={customerNumber}
                                onChange={(e) => setCustomerNumber(e.target.value)}
                            />
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <Typography>Gift Name</Typography>
                            <TextField
                                placeholder="Input 1"
                                variant="outlined"
                                value={giftName}
                                onChange={(e) => setGiftName(e.target.value)}
                                fullWidth
                            />
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <Typography>Package Details</Typography>
                            <TextField
                                placeholder="Input 1"
                                variant="outlined"
                                fullWidth
                                value={packageDetails}
                                onChange={(e) => setPackageDetails(e.target.value)}
                            />
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <Typography>Expdate</Typography>
                            <TextField
                                placeholder="Input 1"
                                variant="outlined"
                                fullWidth
                                value={expDate}
                                onChange={(e) => setExpDate(e.target.value)}
                                type="date"
                            />
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <Typography>Coupon Code</Typography>
                            <TextField
                                placeholder="Input 1"
                                variant="outlined"
                                fullWidth
                                value={couponCode}
                                onChange={(e) => setCouponCode(e.target.value)}
                            />
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <Typography>Price</Typography>
                            <TextField
                                placeholder="Input 1"
                                variant="outlined"
                                fullWidth
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSave}>Save</Button>
                </DialogActions>
            </Dialog> */}
            {/* view giftinvoice */}
            <Dialog open={opengiftinvoice} onClose={handleCloseview} style={{ margin: "10px auto", padding: "30px" }} maxWidth="sm" fullWidth={true}>
                <DialogTitle>Gift Invoice Details</DialogTitle>
                <hr style={{ width: "100%", height: "1px", backgroundColor: "#000" }} />
                <DialogContent >
                    {selectedRow && (
                        <div>
                            <p style={{ fontWeight: "600" }}>name : <span style={{ fontWeight: "400" }}>{selectedRow.name}</span></p>
                            <p style={{ fontWeight: "600" }}>GiftName : <span style={{ fontWeight: "400" }}>{selectedRow.giftName}</span></p>
                            <p style={{ fontWeight: "600" }}>Customer Number : <span style={{ fontWeight: "400" }}>{selectedRow.customerNumber}</span></p>
                            <p style={{ fontWeight: "600" }}>Package details : <span style={{ fontWeight: "400" }}>{selectedRow.packageDetails}</span></p>
                            <p style={{ fontWeight: "600" }}>Exp Date : <span style={{ fontWeight: "400" }}>{selectedRow.expDate}</span></p>
                            <p style={{ fontWeight: "600" }}>CouponCode : <span style={{ fontWeight: "400" }}>{selectedRow.couponCode}</span></p>
                            <p style={{ fontWeight: "600" }}>Price : <span style={{ fontWeight: "400" }}>{selectedRow.price}</span></p>
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseview}>Close</Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={props.snackbar?.response_received}
                close_snack_bar={props.close_snack_bar}
                message={props.snackbar?.message}
            />
        </div >
    )
}
export default BillInvoice;
