import React, { useEffect, useState } from 'react';
import './UserAppoitment.css';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import formLogo from '../../../media/img/newLogo.jpeg';
import { FaEye } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS file for styling
import Skeleton from '@mui/material/Skeleton';
import Loader from "../../../common/loader/containers/loader_cont";
import Snackbar from "../../../common/snackbar/components/snackbar";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PreviewIcon from '@mui/icons-material/Preview';
import { Tooltip } from '@mui/material';
import { DialogTitle, DialogContent, DialogActions, IconButton, TextField, Grid, Box, Autocomplete, FormHelperText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function UserAppoitment(props) {

    const [activeTable, setActiveTable] = useState('product');
    const [message, setMessage] = useState('');
    const [complete, setAppoimentComplete] = useState(false);
    const [customerNumber, setcustomerNumber] = useState('');
    const [note, setnote] = useState('');

    const [formData, setFormData] = useState({
        client: '',
        name: '',
        number: '',
        service: '',
        employee: '',
        date: '',
        time: '',
        dob: '',
        message: '',
    });

    const handleChangedata = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
    };


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [date, todatyDate] = useState(new Date());
    const handleDateChange = (date) => {
        todatyDate(date);
        if (date) {
            // Convert the date to ISO string format
            const formattedDate = formatDate(date);

            // Pass formatted date to API function
            props.Get_All_Appointment_date(formattedDate, props.login.token);
        }
    };
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const handleOpenModal = (row) => {
        setSelectedRow(row);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const formattedDate = date.toISOString().split('T')[0];
    useEffect(() => {
        props.Get_All_Appointment(props.login.token);
        props.Get_All_Appointment_date(formattedDate, props.login.token);
        return () => { };
    }, []);
    const fetchClientNote = (customerNumber) => {
        // Simulating an API call to fetch the note
        const fetchedNote = props.GetClientNote(customerNumber, props.login.token); 
        setnote(fetchedNote); // Set the fetched note in the state
      };
    
      // Handler when "AssignmentTurnedInIcon" is clicked
      const handleOpenDialog = (row) => {
        setAppoimentComplete(true);
        setcustomerNumber(row.customerNumber);
        fetchClientNote(row.customerNumber); // Fetch note for the selected customer
      };
    
      // Handler to save the updated note
      const handleSaveNote = () => {
        // Update the note using the provided API
        props.UpdateClientNote(customerNumber, note, props.login.token);
        setAppoimentComplete(false);
      };
    if (props.loader.open) {
        return (
            <div className='surround'>
                <div>
                    <div className='appointment-text'>
                        <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>

                            <Skeleton variant="rectangular" width={"200px"} height={"43px"} animation="wave" />

                            <Skeleton variant="rectangular" width={"200px"} height={"43px"} animation="wave" />

                        </div>
                    </div>
                </div>
                {/* Table start */}
                <br />
                {activeTable === 'product' && (
                    <div className='appointment-main' id='table1' style={{ maxWidth: "900px" }}>
                        <div style={{ marginLeft: "700px" }}>

                            <Skeleton variant="rectangular" width={"200px"} height={"43px"} animation="wave" />
                        </div>
                        <div className="table-container2">
                            <table className="styled-table">
                                <thead>
                                    <tr>
                                        <Skeleton variant="rectangular" width={"500px"} height={"43px"} animation="wave" />
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.isArray(props.sawappoitment.get_all_appointment_date) && props.sawappoitment.get_all_appointment_date.length > 0 ? (
                                        props.sawappoitment.get_all_appointment_date.map((row, index) => (
                                            <Skeleton variant="rectangular" width={"700px"} height={"43px"} animation="wave" />
                                        ))
                                    ) : (
                                        <tr>
                                            <Skeleton variant="rectangular" width={"500px"} height={"43px"} animation="wave" />
                                        </tr>
                                    )}

                                </tbody>
                            </table>
                        </div>
                    </div>
                )}

                {activeTable === 'service' && (
                    <div className='appointment-main' id='table1' style={{ maxWidth: "900px" }}>
                        <div className="table-container2">
                            <table className="styled-table">
                                <thead>
                                    <Skeleton variant="rectangular" width={"600px"} height={"43px"} animation="wave" />
                                </thead>
                                <tbody>
                                    {Array.isArray(props.sawappoitment.get_all_appointment) && props.sawappoitment.get_all_appointment.length > 0 ? (
                                        props.sawappoitment.get_all_appointment.map((row, index) => (
                                            <Skeleton variant="rectangular" width={"600px"} height={"43px"} animation="wave" />
                                        ))
                                    ) : (
                                        <tr>
                                            <Skeleton variant="rectangular" width={"600px"} height={"43px"} animation="wave" />
                                        </tr>
                                    )}

                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
                {/* Table end */}

                {/* Modal */}
                {isModalOpen && selectedRow && (
                    <Dialog open={isModalOpen} onClose={handleCloseModal} maxWidth="sm" fullWidth>
                        <div className="appointment-overlay">
                            <div className="appointment-content">
                                <button className="appointment-close" onClick={handleCloseModal}>
                                    &times;
                                </button>
                                <h2>Customer Details</h2>
                                <hr className='appointment-line' />
                                <div className="form-container userdetails2">
                                    <div>
                                        <p style={{ fontWeight: "bold" }}>Customer Name</p>
                                        <p style={{ fontWeight: "bold" }}>Customer Number</p>
                                        <p style={{ fontWeight: "bold" }}>Date</p>
                                        <p style={{ fontWeight: "bold" }}>Time</p>
                                        <p style={{ fontWeight: "bold" }}>Service</p>
                                        <p style={{ fontWeight: "bold" }}>DOB</p>
                                        <p style={{ fontWeight: "bold" }}>Message</p>
                                    </div>
                                    <div>

                                        <p style={{ fontWeight: "bold" }}>{selectedRow.name}</p>
                                        <p style={{ fontWeight: "bold" }}>{selectedRow.customerNumber}</p>
                                        <p style={{ fontWeight: "bold" }}>{selectedRow.date.split(0, 10)}</p>
                                        <p style={{ fontWeight: "bold" }}>{selectedRow.timeslot}</p>
                                        <p style={{ fontWeight: "bold" }}>{selectedRow.service}</p>
                                        <p style={{ fontWeight: "bold" }}>{selectedRow.dob}</p>
                                        <p style={{ fontWeight: "bold" }}>{selectedRow.message || '-'}</p>
                                    </div>
                                </div>
                                <div className='cntr'>
                                    <Button style={{ color: "white", backgroundColor: "#008080", margin: "20px 0 30px" }} onClick={handleCloseModal}>
                                        OK
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                )}
            </div>
        )
    }
    else
        return (
            <div className='surround'>
                <div>
                    <div className='appointment-text'>
                        <div className='btn-grp' style={{ margin: "0px" }}>
                            <ButtonGroup variant="">
                                <Button
                                    className={activeTable === 'product' ? 'active2' : ''}
                                    onClick={() => setActiveTable('product')}
                                >
                                    Today's Appointment
                                </Button>
                                <Button
                                    className={activeTable === 'service' ? 'active2' : ''}
                                    onClick={() => setActiveTable('service')}
                                >
                                    All Appointments
                                </Button>
                            </ButtonGroup>
                        </div>
                    </div>
                </div>
                {/* Table start */}
                <br />
                {activeTable === 'product' && (
                    <div className='appointment-main' id='table1' style={{ maxWidth: "900px" }}>
                        <div style={{ marginLeft: "700px" }}>
                            {/* <input
type='date'
onChange={handleDateChange}
dateFormat="yyyy-MM-dd"
placeholderText="Select a date"
// isClearable
value={date}

>
</input> */}
                            <DatePicker
                                selected={date}
                                onChange={handleDateChange}
                                dateFormat="yyyy-MM-dd"
                                placeholderText="Select a date"
                                isClearable
                            />
                        </div>
                        <div className="table-container2">
                            <table className="styled-table">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>Name</th>
                                        <th>Service</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.isArray(props.sawappoitment.get_all_appointment_date) && props.sawappoitment.get_all_appointment_date.length > 0 ? (
                                        props.sawappoitment.get_all_appointment_date.map((row, index) => (
                                            <tr key={index}>
                                                <td>{row.date.split('T')[0]}</td>

                                                <td>{row.timeslot}</td>
                                                <td>{row.name}</td>
                                                <td>{row.service}</td>
                                                <td>
                                                    <Tooltip title="View Appoitment Details">
                                                        <PreviewIcon
                                                            className="action-icon"
                                                            style={{ color: 'purple' }}
                                                            onClick={() => handleOpenModal(row)}
                                                        />
                                                    </Tooltip>
                                                    <Tooltip title="Complete Appoitment">


                                                        <AssignmentTurnedInIcon
                                                            className="action-icon"
                                                            style={{ color: '#6a8c9f' }}
                                                            onClick={() => {
                                                                setAppoimentComplete(true);
                                                                setcustomerNumber(row.customerNumber);
                                                                setnote("");
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="5" style={{ textAlign: 'center' }}>No appointments found</td>
                                        </tr>
                                    )}

                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
                {activeTable === 'service' && (
                    <div className='appointment-main' id='table1' style={{ maxWidth: "900px" }}>
                        <div className="table-container2">
                            <table className="styled-table">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Name</th>
                                        <th>Service</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.isArray(props.sawappoitment.get_all_appointment) && props.sawappoitment.get_all_appointment.length > 0 ? (
                                        props.sawappoitment.get_all_appointment.map((row, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{row.name}</td>
                                                <td>{row.service}</td>
                                                <td>
                                                    <FaEye
                                                        className="action-icon"
                                                        style={{ color: 'purple' }}
                                                        onClick={() => handleOpenModal(row)}
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="4" style={{ textAlign: 'center' }}>No appointments found</td>
                                        </tr>
                                    )}

                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
                {/* Table end */}

                {/* Modal */}
                {isModalOpen && selectedRow && (
                    <Dialog open={isModalOpen} onClose={handleCloseModal} maxWidth="sm" fullWidth>
                        <div className="appointment-overlay">
                            <div className="appointment-content">
                                <button className="appointment-close" onClick={handleCloseModal}>
                                    &times;
                                </button>
                                <h2>Customer Details</h2>
                                <hr className='appointment-line' />
                                <div className="form-container userdetails2">
                                    <div>
                                        <p style={{ fontWeight: "bold" }}>Customer Name</p>
                                        <p style={{ fontWeight: "bold" }}>Customer Number</p>
                                        <p style={{ fontWeight: "bold" }}>Date</p>
                                        <p style={{ fontWeight: "bold" }}>Time</p>
                                        <p style={{ fontWeight: "bold" }}>Service</p>
                                        <p style={{ fontWeight: "bold" }}>DOB</p>
                                        <p style={{ fontWeight: "bold" }}>Message</p>
                                    </div>
                                    <div>

                                        <p style={{ fontWeight: "bold" }}>{selectedRow.name}</p>
                                        <p style={{ fontWeight: "bold" }}>{selectedRow.customerNumber}</p>
                                        <p>{new Date(selectedRow.date).toLocaleString('en-US', {
                                            weekday: 'long',
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: true,
                                        })}</p>
                                        <p style={{ fontWeight: "bold" }}>{selectedRow.timeslot}</p>
                                        <p style={{ fontWeight: "bold" }}>{selectedRow.service}</p>
                                        <p style={{ fontWeight: "bold" }}>{selectedRow.dob}</p>
                                        <p style={{ fontWeight: "bold" }}>{selectedRow.message || '-'}</p>
                                    </div>
                                </div>
                                <div className='cntr'>
                                    <Button style={{ color: "white", backgroundColor: "#008080", margin: "20px 0 30px" }} onClick={handleCloseModal}>
                                        OK
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                )}

                <Dialog open={complete} onClose={() => {
                    setAppoimentComplete(false)
                }} maxWidth="sm" fullWidth>
                    <DialogTitle>
                        Complete Appointment
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={() => {
                                setAppoimentComplete(false);

                            }}
                            aria-label="close"
                            sx={{ position: 'absolute', right: 8, top: 8 }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <form>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={4}
                                        label="Note"
                                        placeholder="Guidance"
                                        value={note}
                                        onChange={(e) => setnote(e.target.value)}
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                // setnote(""); 
                                setAppoimentComplete(false)
                            }}
                            style={{ color: "#6a8c9f", border: "1px solid #6a8c9f" }}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            style={{ backgroundColor: "#6a8c9f", color: "white" }}
                            onClick={() => {
                                props.UpdateClientNote(customerNumber, note, props.login.token);
                                setAppoimentComplete(false);
                                // setnote("")
                            }}
                        >
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
                <Loader />
                <Snackbar
                    open={props.snackbar.response_received}
                    close_snack_bar={props.close_snack_bar}
                    message={props.snackbar.message}
                />
            </div>
        )
}

export default UserAppoitment;
