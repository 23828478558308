import React, { Component } from "react";
import { connect } from "react-redux";
// import { close_snack_bar } from "../../../../common/snackbar/action";
import { close_snack_bar } from "../../../common/snackbar/action";
import Appointment from "../../Appoitment/components/Appointment";
import { view_all_appointment, addnewappointment, delete_appointment, slotavailable, setFreeSlot, slotavailableview, view_all_appointmentByDate ,updatenewappointment} from "../action";
import { viewAllClient } from "../../AllClients/action"
import { View_All_Service } from "../../ProductService/action"
import { view_all_employee } from "../../AllEmployee/action"

export class Controller extends Component {
  render() {
    return <Appointment {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    appointment: store.appointment,
    client: store.client,
    viewallservices: store.viewallservices,
    allemployee: store.allemployee,

  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    view_all_appointment: (token) => {
      dispatch(view_all_appointment(token));
    },
    viewAllClient: (token) => {
      dispatch(viewAllClient(token));
    },
    View_All_Service: (token) => {
      dispatch(View_All_Service(token));
    },
    updatenewappointment:(id, phn, name,dob,service,date,assignEmployee,timeslot,message, token )=>{
   dispatch(updatenewappointment(id, phn, name,dob,service,date,assignEmployee,timeslot,message, token ));
     console.log(id, phn, name,dob,service,date,assignEmployee,timeslot,message, token);
    },
    view_all_employee: (token) => {
      dispatch(view_all_employee(token));
    },
    addnewappointment: (phn, name, dob, service, date, assignEmployee, timeslot, message, token) => {
      dispatch(addnewappointment(phn, name, dob, service, date, assignEmployee, timeslot, message, token))
    },
    delete_appointment: (appointmentid, token) => {
      dispatch(delete_appointment(appointmentid, token));
    },
    slotavailable: (service, date, timeslot, token) => {
      dispatch(slotavailable(service, date, timeslot, token));
    },
    slotavailableview: (empid, date, token) => {
      dispatch(slotavailableview( empid,date, token));
    },
    setFreeSlot: (payload) => {
      dispatch(setFreeSlot(payload));
    },
    view_all_appointmentByDate: (date, token) => {
      dispatch(view_all_appointmentByDate(date, token));
      // console.log( token)
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
