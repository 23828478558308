import React, { Component } from "react";
import { connect } from "react-redux";
import AllEmployee from "../components/AllEmployee";
// import { close_snack_bar } from "../../../../common/snackbar/action";
 import { close_snack_bar } from "../../../common/snackbar/action";
 
import { view_all_employee,addnewemployee,delete_employee,update_employee } from "../action";
import {View_All_Service} from "../../ProductService/action";
export class Controller extends Component {
  render() {
    return <AllEmployee {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    allemployee:store.allemployee,
    viewallservices: store.viewallservices,

  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    view_all_employee: (token) => {
      dispatch(view_all_employee(token));
    },
    View_All_Service: (token) => {
      dispatch(View_All_Service(token));
    },
    addnewemployee: (  name,contactNo,email,password,AltNumber, token) =>{
      dispatch(addnewemployee(  name,contactNo,email,password,AltNumber, token));
    },
    delete_employee:(employeeid,token)=>{
      dispatch(delete_employee(employeeid,token))
    },
    update_employee:(empid, name,contactNo,email,password,AltNumber, token) =>{
      dispatch(update_employee(empid, name,contactNo,email,password,AltNumber, token))
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
 