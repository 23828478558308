import React, { Component } from "react";
import { connect } from "react-redux";
import Login from "../components/login";
import {
  close_snack_bar
} from "../../common/snackbar/action";
import {

  clear_login,
  super_login
 
} from "../actions";
export class Controller extends Component {
  render() {
    return (
      <Login {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    clear_login: () => {
      dispatch(clear_login())
    },
    super_login: (email,password) => {
      dispatch(super_login(email,password))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
  
  
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);