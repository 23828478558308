import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { green, pink } from "@mui/material/colors";
import Box from "@mui/material/Box";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import ListItem from "@mui/material/ListItem";
import Menu from '@mui/material/Menu';
import ListItemIcon from "@mui/material/ListItemIcon";
import Logo from "../../media/img/WhatsApp Image 2024-08-23 at 12.32.17 PM 1.png"
import { Link } from "react-router-dom";
import Icon from "@mui/material/Icon";
import LG_Cont from "../containers/Lg_Cont";
import "../styles/drawer.css";
import formLogo from '../../media/img/newLogoTrans.png';

// import logo from "../../assets/img/Group 3237.png"
// import Logo from "../../assets/img/faces/thumbnail_Kids_Tryz_FINALLOGO_BGLESS-01 2.png"
import {
  all_admin_option,
  // all_super_admin_options,
  // all_delar_options,
  // all_delar_user_options
  // all_manager_options,
} from "../constant";
import { red } from "@mui/material/colors";
import { useLocation } from 'react-router-dom'

import { Avatar, Badge, Grid } from "@mui/material";
const drawerWidth = 140;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  height: 50,
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  // backgroundColor:'red',
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
const useStyles = (theme) => ({
  root: {
    display: "flex",
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // backgroundColor: "#FF7000"
    background: "#fadde1",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),

    backgroundColor: "#11101d",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#11101d",

    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    // backgroundColor: '#FF7000',
    backgroundColor: "#11101d",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  iconStyle: {
    position: "relative",
    left: 8,
    top: 3,
    fontSize: 12,
    marginRight: 5,
    //   color:'red'
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  grow: {
    flexGrow: 1,
  },
  Icon: {
    //   color:"red",
    position: "relative",
    left: "10px",
  },

  ListItem: {
    border: "1px solid red",

    
  },
});

export default function MiniDrawer(props) {
  const classes = useTheme();
  const theme = useTheme();
  const [showLoader, setShowLoader] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  React.useEffect(() => {
    // Hide the loader after 2 seconds
    const timer = setTimeout(() => {
        setShowLoader(false);
    }, 2000);

    return () => clearTimeout(timer);
}, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const location = useLocation();
  console.log(props.role);
  
  function sideBar(role, open) {
    var side_top = (
      <div>
        {/* {open ?
          <div style={{ backgroundColor:"#11101d", marginTop:-30 }}>
            <Avatar alt={localStorage.getItem("name")} src={localStorage.getItem("profile_img")} className="drawer_img" >
              {localStorage.getItem("name") == null ? "" : localStorage.getItem("name").substring(0, 1).toUpperCase()}
            </Avatar>
            <Typography variant="subtitle1" className="profile-name" color="secondary">
              Hi! {localStorage.getItem("qubi7_name")}
            </Typography>
          </div>
          :
          <Avatar alt={localStorage.getItem("name")} src={localStorage.getItem("profile_img")} className="drawer_img_closed" style={{position:"relative",left:"15px"}} >
            {localStorage.getItem("name") == null ? "" : localStorage.getItem("name").substring(0, 1).toUpperCase()}
          </Avatar>
        } */}
      </div>
    );

    if (role === "admin") {
      return (
        <div style={{ marginTop: 1 }}>
        {side_top}
        {all_admin_option.map((option) => (
            location.pathname === option.link ? (
                <center 
                    key={option.name} 
                    style={{ 
                        display: "flex", 
                        flexDirection: "column", 
                        backgroundColor: "#F3F4F8", 
                        justifyContent: "center", 
                        alignItems: "center", 
                        height: "70px", // Adjust height as needed
                        width: "100%", 
                        background: "linear-gradient(90deg, #FFF 0%, rgba(255, 255, 255, 0.00) 95.87%)", 
                        // opacity: 0.6 
                    }}>
                    <ListItemIcon style={{ display: "flex", justifyContent: "center" }}>
                        <Icon className="drawer_icon" style={{ fontSize: "24px" }}>{option.icon}</Icon>
                    </ListItemIcon>

                    <ListItem  key={option.name} className="drawer_text" style={{ padding: "0" ,justifyContent: "center" }}>
                        <Typography style={{ fontSize: "14px", fontWeight: "bold", textAlign: "center" }}>
                            {option.name}
                        </Typography>
                    </ListItem>
                </center>
            ) : (
                <Link key={option.link} to={option.link} style={{ textDecoration: "none" }}>
                    <center 
                        style={{ 
                            display: "flex", 
                            flexDirection: "column", 
                            justifyContent: "center", 
                            alignItems: "center", 
                            height: "70px", // Adjust height as needed
                            // width: "100%" 
                        }}>
                        <ListItemIcon style={{ display: "flex", justifyContent: "center" }}>
                            <Icon className="drawer_icons" style={{ fontSize: "24px" }}>{option.icon}</Icon>
                        </ListItemIcon>

                        <ListItem className="drawer_texts" style={{ padding: "0" ,justifyContent: "center" }}>
                            <Typography style={{ fontSize: "14px", fontWeight: "bold", textAlign: "center !important" }}>
                                {option.name}
                            </Typography>
                        </ListItem>
                    </center>
                </Link>
            )
        ))}
    </div>
);
};
  }
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={true} style={{ backgroundColor: 'white' }}>
        <Toolbar style={{ justifyContent: "space-between" }}>


        
         <Typography variant="h6" noWrap component="div" style={{ color: 'blue' }}>
           
          </Typography>


          <div className={classes.sectionDesktop}>
            {/* <img src={Logo}  alt="no_img"
              style={{
               
       
                // width: 95,
                height: 80,
              }}/> */}
            
            {showLoader && (
                    <div className='logoLoder'>
                        <img src={formLogo} alt="Loading..." />
                    </div>
                )}
            <Box sx={{ flexGrow: 0 }}>
          
              <Tooltip title="Profile">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt={props.login.role} src="https://www.ihna.edu.au/blog/wp-content/uploads/2022/10/user-dummy.png"  style={{textTransform:"capitalize"}}/>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >

                {/* <MenuItem onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">Profile</Typography>
                </MenuItem> */}
                <MenuItem onClick={handleCloseUserMenu}>
                  <LG_Cont />
                </MenuItem>

              </Menu>
            </Box>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={true}>
        {/* <DrawerHeader style={{display:'flex',flexDirection:"column"}} > */}
        <Grid
          style={{ backgroundColor: "#6A8C9F",  height: "100%" }}
        >
          <center>
            <img
              src={Logo}
              variant="rounded"
              alt="no_img"
              style={{
                marginTop: "27px",
                // marginLeft: 0,
                width: '5vw',

              }}
            />
            {/* <img  /> */}

            {/* {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />} */}
            {/* </Badge> */}
          </center>

          {/* <Box className="name">
          <Typography
              style={{ color: "white ", marginTop: "6px", textAlign: "center" }}
            >
              {props.login.role}
            </Typography> 
          </Box> */}
          {/* </DrawerHeader> */}
         
          {/* <List> */}
          <List>{sideBar(props.login.role, open)}</List>
          {/* </List> */}
          <center>
            {/* <img src={logo} alt="no_img" height="95" width="130" style={{marginTop:"-10px"}}/> */}
          </center>
          <center>

          </center>
        </Grid>
      </Drawer>
    </Box>
  );
}
