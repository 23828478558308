import { ADD_ALL_SERVICES, VIEW_ALL_GIFT_PACKAGE,VIEW_ALL_GIFT_CUSTOMER } from "./Constant";
const initial_state = {
    view_all_gift_package: [],
    view_all_gift_customer: [],
    count: 0,
};
export default function reducer(state = initial_state, action) {
    switch (action.type) {
        case VIEW_ALL_GIFT_PACKAGE:
            return (state = {
                ...state,
                view_all_gift_package: action.payload.data,
                count: action.payload.count,
            });
        case VIEW_ALL_GIFT_CUSTOMER:
            return (state = {
                ...state,
                view_all_gift_customer: action.payload.data,
                count: action.payload.count,
            });
        case ADD_ALL_SERVICES: return {
            ...state,
            loading: true
        };
        default:
            return state;
    }
}