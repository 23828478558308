import { VIEW_ALL_GIFT_CUSTOMER, VIEW_ALL_GIFT_PACKAGE } from "./Constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";
import "firebase/storage";
import "firebase/storage";
import { set_snack_bar } from "../../common/snackbar/action";
import {
    getStorage,
    ref,
    uploadBytesResumable,
    getDownloadURL,
    uploadString,
} from "firebase/storage";


export function viewAllGiftPackage(token) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(
            UNIVERSAL.BASEEMP +
            "get-all-gift-packages",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        )
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({
                        type: VIEW_ALL_GIFT_PACKAGE,
                        payload: {
                            data: responseJson.result.data.result,
                            count: responseJson.result.data.result.length,
                        },
                    });
                    dispatch(set_snack_bar(true, "Data View Sucessfully"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({
                        type: VIEW_ALL_GIFT_PACKAGE,
                        payload: { data: [], count: 0 },
                    });
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function Delete_all_giftpackage(id, token) {
    // console.log(id, token)
    return (dispatch) => {
        console.log(id, token)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEEMP + "delete-giftpackage/" + id, {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: token
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(viewAllGiftPackage(token));

                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function Add_Gift_package(giftName, packageDetails, price, token) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEEMP + "/addGiftPackage", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: token,
            },
            body: JSON.stringify({
                giftName: giftName,
                packageDetails: packageDetails,
                price: price,
            }),
        })
            .then((response) => response.json())

            .then((responseJson) => {
                console.log(responseJson);
                if (responseJson.status) {
                    dispatch(viewAllGiftPackage(token));
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));

            });
    };
}
export function Update_All_Gift_package(giftName, packageDetails, price, id, token) {
    return (dispatch) => {
        dispatch(setLoader());
        //   console.log(service,price)
        return fetch(UNIVERSAL.BASEEMP + "update-giftpackage/" + id, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: token
            },
            body: JSON.stringify({
                giftName: giftName,
                packageDetails: packageDetails,
                price: price,
            }),
        })
            .then((response) => response.json())

            .then((responseJson) => {
                console.log(responseJson);
                if (responseJson.status) {
                    dispatch(viewAllGiftPackage(token));

                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function viewAllGiftPackageforcutomer(token) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(
            UNIVERSAL.BASEEMP +
            "get-all-gift-customers",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        )
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({
                        type: VIEW_ALL_GIFT_CUSTOMER,
                        payload: {
                            data: responseJson.result.data.result,
                            count: responseJson.result.data.result.length,
                        },
                    });
                    dispatch(set_snack_bar(true, "Data View Sucessfully"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({
                        type: VIEW_ALL_GIFT_CUSTOMER,
                        payload: { data: [], count: 0 },
                    });
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function Delete_all_giftCustomer(id, token) {
    // console.log(id, token)
    return (dispatch) => {
        console.log(id, token)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEEMP + "delete-gift-customer/" + id, {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: token
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(viewAllGiftPackageforcutomer(token));

                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function Add_Gift_Customer(giftName,customerNumber,name, packageDetails,expDate, couponCode, token) {
    console.log(giftName,customerNumber,name, packageDetails,expDate, couponCode, token)
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEEMP + "createGift", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: token,
            },
            body: JSON.stringify({
                giftName: giftName,
                customerNumber:customerNumber,
                name:name,  
                packageDetails: packageDetails,
                couponCode:couponCode,
                expDate:expDate,
            }),
        })
            .then((response) => response.json())

            .then((responseJson) => {
                console.log(responseJson);
                if (responseJson.status) {
                    dispatch(viewAllGiftPackageforcutomer(token));
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));

            });
    };
}
export function Update_All_Gift_Customer(giftName,customerNumber,name, packageDetails,expDate, couponCode,id, token) {
    console.log(giftName,customerNumber,name, packageDetails,expDate, couponCode,id, token)
    return (dispatch) => {
        dispatch(setLoader());
        //   console.log(service,price)
        return fetch(UNIVERSAL.BASEEMP + "update-gift-customer/" + id, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: token
            },
            body: JSON.stringify({
                giftName: giftName,
                customerNumber:customerNumber,
                name:name,  
                packageDetails: packageDetails,
                couponCode:couponCode,
                expDate:expDate,
            }),
        })
            .then((response) => response.json())

            .then((responseJson) => {
                console.log(responseJson);
                if (responseJson.status) {
                    dispatch(viewAllGiftPackageforcutomer(token));

                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
