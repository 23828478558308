import { ADD_ALL_SERVICES, VIEW_ALL_PRODUCTS, VIEW_ALL_SERVICES } from "./Constant";
const initial_state = {
    view_all_service: [],
    view_all_Products:[],
    count: 0,
};
export default function reducer(state = initial_state, action) {
    switch (action.type) {
        case VIEW_ALL_SERVICES:
            return (state = {
                ...state,
                view_all_service: action.payload.data,
                count: action.payload.count,
            });
        case ADD_ALL_SERVICES: return {
            ...state,
            loading: true
        };
        case VIEW_ALL_PRODUCTS:
            return (state = {
                ...state,
                view_all_Products: action.payload.data,
                count: action.payload.count,
            });
        default:
            return state;
    }
}