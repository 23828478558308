// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** @format */

.appointmentopen-modals {
  padding: 10px 40px;
  font-size: 17px;
  background-color: #6a8c9f !important;
  color: #fff !important;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 600;
}
.search-bar {
  position: relative;
  width: 300px; 
  height: 30px !important; 
  display: flex;
  align-items: center;
  border-radius: 5px;
  /* background-color: #f0f0f0;  */
  padding: 0 10px;
}

.search-input {
  width: 100%;
  height: 100%;
  padding: 0 40px 0 10px; 
  border: none;
  border-radius: 5px;
  font-size: 16px;
  background-color: transparent; 
  outline: none;
}

.search-icon {
  position: absolute;
  right: 10px;
  color: #888; 
  font-size: 18px;
}
.search-input .MuiOutlinedInput-root {
   background-color: #f0f0f0; 
  border-radius: 4px; 
}

.search-input .MuiOutlinedInput-notchedOutline {
  border: 1px solid #ccc; 
}

.search-input .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 0.2px solid #ccc; 
}

.search-input .MuiInputBase-input {
  height: 10px; 
}
`, "",{"version":3,"sources":["webpack://./src/pages/Appoitment/components/Appointment.css"],"names":[],"mappings":"AAAA,aAAa;;AAEb;EACE,kBAAkB;EAClB,eAAe;EACf,oCAAoC;EACpC,sBAAsB;EACtB,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,YAAY;EACZ,uBAAuB;EACvB,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,gCAAgC;EAChC,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,6BAA6B;EAC7B,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,WAAW;EACX,eAAe;AACjB;AACA;GACG,yBAAyB;EAC1B,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,YAAY;AACd","sourcesContent":["/** @format */\n\n.appointmentopen-modals {\n  padding: 10px 40px;\n  font-size: 17px;\n  background-color: #6a8c9f !important;\n  color: #fff !important;\n  border: none;\n  border-radius: 10px;\n  cursor: pointer;\n  font-weight: 600;\n}\n.search-bar {\n  position: relative;\n  width: 300px; \n  height: 30px !important; \n  display: flex;\n  align-items: center;\n  border-radius: 5px;\n  /* background-color: #f0f0f0;  */\n  padding: 0 10px;\n}\n\n.search-input {\n  width: 100%;\n  height: 100%;\n  padding: 0 40px 0 10px; \n  border: none;\n  border-radius: 5px;\n  font-size: 16px;\n  background-color: transparent; \n  outline: none;\n}\n\n.search-icon {\n  position: absolute;\n  right: 10px;\n  color: #888; \n  font-size: 18px;\n}\n.search-input .MuiOutlinedInput-root {\n   background-color: #f0f0f0; \n  border-radius: 4px; \n}\n\n.search-input .MuiOutlinedInput-notchedOutline {\n  border: 1px solid #ccc; \n}\n\n.search-input .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {\n  border: 0.2px solid #ccc; \n}\n\n.search-input .MuiInputBase-input {\n  height: 10px; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
