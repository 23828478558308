import React, { Component } from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import {
    styled, Grid, CardContent, DialogActions,
    DialogTitle, Dialog, Typography, DialogContent
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import "../style/Assets.css";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Badge from '@mui/material/Badge';
// import edit from "../../../pages/image/edit.png";
import edit from '@mui/icons-material/Edit';
import { addUploadAsset } from '../actions';
import Loader from "../../../common/loader/containers/loader_cont";
import Snackbar from "../../../common/snackbar/components/snackbar";
// import Clip from "../../../pages/image/paperclip-2.png"
import Clip from '@mui/icons-material/Edit';
import { TextareaAutosize } from '@mui/base';
import Skeleton from '@mui/material/Skeleton';
// import Search from "../../image/vuesax-outline-search-normal.png";
import Search from '@mui/icons-material/Edit';
// import Trash from "../../image/trash.png";
import Trash from '@mui/icons-material/Edit';

export default class Assets extends Component {
    constructor(props) {
        super(props);
        this.state = {

            delete: false,
            edit: false,
            deleteall: false,

            add: false,
            addname: "",
            addduration: "",
            attachmnet: "",
            index: 0,
            assets_name: "",
            duration: "",
            editattachment: "",
            all_check: false,
            chk: false,
            isChecked: [],
            assets_id: "",

        }
    }
    componentDidMount() {
        document.body.style.backgroundColor = "#f5edff";
        this.props.viewAllAssets(this.props.login.org_id);
        
    }
    CheckedAssets(p, index, assets) {
        // ;
        if (p.target.checked) {
            this.props.CheckedAssets(index, true, 1, assets)
        }
        else {
            this.props.CheckedAssets(index, false, 0, assets)
        }
    }
    AllCheckedAssets(p, assets) {
        // ;
        if (p.target.checked) {
            // this.props.CheckedAssets(index,)
            this.props.allCheck(true, 1, assets)
        }
        else {
            // this.props.CheckedAssets(index, false, 0, assets)
            this.props.allCheck(false, 0, assets)
        }

    }


    render() {
        const {
            assets,
            login,
            close_snack_bar,
            snackbar,
        } = this.props;
        if (this.props.loader.open) {
            return (
                <Grid>
                    <div style={{ width: "98%", marginTop: "85px", }}>

                        <Paper
                            component="form"
                            style={{ display: "flex", marginLeft: "auto", width: "367px", height: "43px", backgroundColor: "rgba(121, 0, 255, 0.08)", boxShadow: "none" }}
                        >
                            <Skeleton variant="rectangular" width={"367px"} height={"43px"} animation="wave" />
                        </Paper>

                    </div>

                    <CardContent style={{ marginLeft: "1.5%" }}>
                        <TableContainer component={Paper}>
                            <div style={{ display: "flex", flexdirection: "row" }}>
                                {/* <h2 style={{ marginLeft: "2%" }}>Companies</h2> */}
                                <Skeleton variant="rectangular" width={150} height={40} animation="wave" style={{ margin: "2%" }} />
                                <Skeleton variant="rectangular" width={120} height={40} animation="wave" style={{ marginLeft: "auto", height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "2%" }} />
                                <Skeleton variant="rectangular" width={120} height={40} animation="wave" style={{ height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "2%", marginRight: "3%", marginLeft: "1%", }} />
                            </div>
                            <Table style={{ minWidth: 600, marginTop: "2%", }} size="small" aria-label="a dense table">
                                <TableHead >
                                    <TableRow style={{ backgroundColor: "#E3E3E3" }} >

                                        <TableCell>
                                            <Skeleton variant="rectangular" animation="wave" />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableRow>
                                    <TableCell style={{ fontSize: "18px" }}>
                                        <Skeleton variant="rectangular" width={"100%"} animation="pulse" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "18px" }}>
                                        <Skeleton variant="rectangular" width={"100%"} animation={"pulse"} />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "18px" }}>
                                        <Skeleton variant="rectangular" width={"100%"} animation={"pulse"} />
                                    </TableCell>
                                </TableRow>

                            </Table>
                        </TableContainer>

                    </CardContent>

                </Grid>
            )
        }
        else
            return (
                <Grid>
                    <div style={{ marginRight: "1%", marginTop: "80px", }}>
                        <Paper
                            component="form"
                            style={{ display: "flex", marginLeft: "auto", width: "367px", height: "43px", backgroundColor: "rgba(121, 0, 255, 0.08)", boxShadow: "none", opacity: "0.699999988079071", borderRadius: "8px" }}
                        >
                            <IconButton
                                type="button"
                                style={{ p: "15px" }}
                                aria-label="search"
                            >
                                <img src={Search} />
                            </IconButton>
                            <InputBase
                                style={{ ml: 5, flex: 1, fontSize: "16px", fontFamily: "Montserrat", color: "#8C8C8C", fontWeight: "300" }}
                                placeholder="Search here"
                                inputProps={{ "aria-label": "Search here" }}
                                onChange={(e) => this.props.search_asserts(assets.assets, e.target.value)}
                            />
                        </Paper>

                    </div>


                    <CardContent style={{ marginLeft: "1.5%" }}>
                        <TableContainer component={Paper}>
                            <div style={{ display: "flex", flexdirection: "row", marginBottom: "20px" }}>
                                <Typography style={{ marginLeft: "2%", marginTop: "1%", fontSize: "27px", fontWeight: "600" }}>
                                    All Assets
                                </Typography>
                               { this.props.login.type!=="Finance" &&<Button
                                    onClick={() => {
                                        this.setState({
                                            add: true,
                                        })
                                    }}
                                    style={{ marginLeft: "auto", height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "1.5%", border: "1px solid rgba(0, 0, 0, 0.4)" }} variant="outlined">
                                    Add Assets </Button>}
                                {this.props.login.type!=="Finance" &&<Button
                                    onClick={() => {
                                        this.setState({
                                            deleteall: true,

                                        })


                                    }}
                                    style={{ height: "40px", color: "rgba(0, 0, 0, 0.4)", marginTop: "1%", marginLeft: "1%", border: "2px solid rgba(0, 0, 0, 0.4)", width: "8%", marginRight: "1%" }} startIcon={<DeleteOutlineOutlinedIcon style={{ color: "rgba(0, 0, 0, 0.4)" }} />}>
                                    Delete
                                </Button>}
                            </div>
                            <Table size="small" aria-label="a dense table">
                                <TableHead className="tablehead">
                                    <TableRow style={{ backgroundColor: "#E3E3E3" }} >
                                        <TableCell style={{ fontSize: "16px", width: "5%" }}><Checkbox
                                            checked={this.props.assets.assets.allCheck}
                                            onClick={(p) => {
                                                {
                                                    this.AllCheckedAssets(p, this.props.assets.assets)
                                                }
                                            }}
                                        /></TableCell>
                                        <TableCell style={{ textAlign: "left", fontSize: "13px", width: "15%" }}>Sl no</TableCell>
                                        <TableCell style={{ textAlign: "left", fontSize: "13px", width: "25%" }}>Assets Name</TableCell>
                                        <TableCell style={{ textAlign: "left", fontSize: "13px" }}>Duration</TableCell>
                                        <TableCell style={{ textAlign: "left", fontSize: "13px" }}></TableCell>

                                    </TableRow>
                                </TableHead>

                                {Array.isArray(this.props.assets.search_asserts) && this.props.assets.search_asserts.map((item, index) => {
                                    return (
                                        <TableRow hover={true}>
                                            <TableCell style={{ fontSize: "16px", width: "5%" }}><Checkbox
                                                // value={this.props.assets.assets.assets_id}
                                                checked={item.checked}
                                                // {...checked===true?this.state.isChecked.push(item.assets_id):false}
                                                onClick={(p) => { this.CheckedAssets(p, index, this.props.assets.assets) }}
                                            /></TableCell>
                                            <TableCell style={{ textAlign: "left", fontSize: "13px", width: "15%" }}>{index + 1}</TableCell>
                                            <TableCell style={{ textAlign: "left", fontSize: "13px", width: "25%" }}>{item.assets_name}</TableCell>

                                            <TableCell style={{ textAlign: "left", fontSize: "13px" }}>{item.renewal_duration}</TableCell>
                                            <TableCell style={{ fontSize: "13px", textAlign: "end" }}>
                                                <Button
                                                    style={{ backgroundColor: "#81FF7E", width: "5px", border: "2px solid #038300" }}
                                                    onClick={() => {
                                                        this.setState({
                                                            edit: true,
                                                            _id: item._id,
                                                            assets_name: item.assets_name,
                                                            renewal_duration: item.renewal_duration,
                                                            editattachment: item.attachmnet.substring(8, 16)
                                                        })
                                                    }}>
                                                    <img style={{ width: 20, height: 20 }} src={edit} />
                                                </Button>
                                                <Button
                                                    style={{ backgroundColor: "#FF9696", marginLeft: "3%", border: "2px solid #850000" }}
                                                    onClick={() => {
                                                        this.setState({
                                                            delete: true,
                                                            assets_id: item._id
                                                        })

                                                        // this.props.deleteAsset(this.props.login.org_id, this.props.assets.assets.assets_id);
                                                    }} >
                                                    <DeleteOutlineOutlinedIcon style={{ width: 20, height: 20, color: "#850000" }} />
                                                </Button>
                                            </TableCell>
                                        </TableRow>

                                    )
                                })}

                            </Table>
                        </TableContainer>
                    </CardContent>
                    <Dialog
                        open={this.state.add}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        style={
                            {

                            }}
                    >
                        <Grid
                            style={{

                                minWidth: "600px",
                                // height: "380px"

                            }}
                        >
                            <Grid>
                                <DialogTitle>
                                    <Grid container>
                                        <Grid item xs={11} />
                                        <Grid item xs={1}>
                                            <IconButton onClick={() => {
                                                this.setState({ add: false });
                                            }}>
                                                <CloseOutlinedIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container direction={"column"} spacing={2}>
                                        <Grid item>
                                            <Grid container>
                                                <Grid item xs={3}>
                                                    <Typography style={{ fontSize: 'large' }}>
                                                        Assets Name<strong style={{ color: "red" }}>*</strong>:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2} height={40}>
                                                    <TextField
                                                        style={{ width: '253px', }}

                                                        id="outlined-select-currency-native"

                                                        onChange={(text) => this.setState({ addname: text.target.value })}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container>
                                                <Grid item xs={3}>
                                                    <Typography style={{ fontSize: 'large' }}>
                                                        Duration<strong style={{ color: "red" }}>*</strong>:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2} height={40}>
                                                    <TextField
                                                        style={{ width: '253px', }}
                                                        id="outlined-select-currency-native"
                                                        select
                                                        onChange={(e) => this.setState({ addduration: e.target.value })}
                                                    >
                                                        <MenuItem value={'Yearly'}>
                                                            yearly
                                                        </MenuItem>
                                                        <MenuItem value={'Monthly'}>
                                                            Monthly
                                                        </MenuItem>
                                                        <MenuItem value={'Packages'}>
                                                            Packages
                                                        </MenuItem>
                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container>
                                                <Grid item xs={3}>
                                                    <Typography style={{ fontSize: 'large' }}>
                                                        Attachment:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8} style={{ border: "1px solid grey", backgroundColor: "#FCFCFC", borderRadius: 8, height: 80 }} >
                                                    <label htmlFor="file" style={{ display: "flex", flexDirection: "row", }}
                                                    >
                                                        <AttachFileIcon style={{ marginLeft: "3%", marginTop: "3%" }} />
                                                        <Typography style={{ fontSize: "18px", marginTop: "3%" }}
                                                        //   accept=".png,.jpg,.jpeg,image/png,image/jpg,image/jpeg"
                                                        >
                                                            {this.state.attachmnet !== "" ? (this.state.attachmnet).name : "Attach File"}
                                                        </Typography>
                                                    </label>
                                                    <input style={{ display: "none" }} type="file" id="file"
                                                        onChange={(e) => { this.setState({ attachment: e.target.files[0] }) }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={() => { this.setState({ add: false }) }}
                                        style={{ backgroundColor: "#D9D9D9", marginTop: "4%", width: "148px", color: "#8C8C8C", height: "44px" }} variant="contained" >
                                        Cancel
                                    </Button>
                                    <Button
                                        disabled={(this.state.addname === '') || (this.state.addduration === '')}
                                        onClick={() => {
                                            this.props.addUploadAsset(this.props.login.org_id, this.state.addname, this.state.addduration, this.state.attachmnet)
                                            this.setState({ add: false, addname: "", addduration: "", attachmnet: "" });
                                        }}
                                        // onClick={() => { this.setState({ add: false }) }}
                                        style={{ backgroundColor: "#7900FF", marginTop: "4%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                        Save
                                    </Button>
                                </DialogActions>
                            </Grid>
                        </Grid>
                    </Dialog >

                    <Dialog
                        open={this.state.edit}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <Grid
                            style={{
                                minWidth: "600px",
                            }}
                        >
                            <Grid>
                                <DialogTitle>
                                    <Grid container>
                                        <Grid item xs={11} />
                                        <Grid item xs={1}>
                                            <IconButton onClick={() => {
                                                this.setState({ edit: false });
                                            }}>
                                                <CloseOutlinedIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container direction={"column"} spacing={2}>
                                        <Grid item>
                                            <Grid container>
                                                <Grid item xs={3}>
                                                    <Typography style={{ fontSize: 'large' }}>
                                                        Assets Name<strong style={{ color: "red" }}>*</strong>:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2} height={40}>
                                                    <TextField
                                                        style={{ width: '253px', }}
                                                        id="outlined-select-currency-native"
                                                        value={this.state.assets_name}
                                                        onChange={(text) => this.setState({ assets_name: text.target.value })}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container>
                                                <Grid item xs={3}>
                                                    <Typography style={{ fontSize: 'large' }}>
                                                        Duration<strong style={{ color: "red" }}>*</strong>:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2} height={40}>
                                                    <TextField
                                                        style={{ width: '253px', }}
                                                        id="outlined-select-currency-native"
                                                        select
                                                        value={this.state.renewal_duration}
                                                        onChange={(e) => this.setState({ renewal_duration: e.target.value })}
                                                    >
                                                        <MenuItem value={'Yearly'}>
                                                            yearly
                                                        </MenuItem>
                                                        <MenuItem value={'Monthly'}>
                                                            Monthly
                                                        </MenuItem>
                                                        <MenuItem value={'Packages'}>
                                                            Packages
                                                        </MenuItem>
                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container>
                                                <Grid item xs={3}>
                                                    <Typography style={{ fontSize: 'large' }}>
                                                        Attachment:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8} style={{ border: "1px solid grey", backgroundColor: "#FCFCFC", borderRadius: 8, height: 80 }} >
                                                    <label htmlFor="file" style={{ display: "flex", flexDirection: "row", }}
                                                    >
                                                        <AttachFileIcon style={{ marginLeft: "3%", marginTop: "3%" }} />
                                                        <Typography style={{ fontSize: "18px", marginTop: "3%" }}
                                                        //   accept=".png,.jpg,.jpeg,image/png,image/jpg,image/jpeg"
                                                        >
                                                            {this.state.attachmnet !== "" ? (this.state.attachmnet).name : "Attach File"}
                                                        </Typography>
                                                    </label>
                                                    <input style={{ display: "none" }} type="file" id="file"
                                                        onChange={(e) => { this.setState({ editattachment: e.target.files[0] }) }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={() => { this.setState({ edit: false }) }}
                                        style={{ backgroundColor: "#D9D9D9", marginTop: "4%", width: "148px", color: "#8C8C8C", height: "44px" }} variant="contained" >
                                        Cancel
                                    </Button>
                                    <Button                                  
                                        onClick={() => {
                                            this.props.uploadUpdateAssets(this.props.login.org_id, this.state._id, this.state.assets_name, this.state.renewal_duration, this.state.attachmnet)
                                            this.setState({ edit: false, _id: "", assets_name: "", renewal_duration: "" ,attachmnet:""});
                                        }}                                     
                                        style={{ backgroundColor: "#7900FF", marginTop: "4%", width: "148px", marginLeft: "3%", height: "44px" }} variant="contained" >
                                        Save
                                    </Button>
                                </DialogActions>
                            </Grid>
                        </Grid>
                    </Dialog >


                    <Dialog
                        open={this.state.delete}
                        onClose={() => {
                            this.setState({ delete: false });
                        }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        style={{ width: "100%", height: "100%", }}
                    >
                        {/* <IconButton
                    > */}

                        <Grid style={{ backgroundColor: "#F8F8F8", height: "320px", minWidth: "380px" }}>


                            <center>
                                <ErrorOutlineOutlinedIcon style={{ marginTop: "3%", fontSize: "84px", color: "#FF0000" }} />
                                <DialogTitle style={{ fontSize: "22px", color: "#FF0000" }}>Are You Sure ?</DialogTitle>
                                <Typography style={{ fontSize: "18px" }}>You want to delete</Typography >

                            </center>
                            <DialogActions style={{ marginTop: "42px" }}>

                                <Button variant="contained"
                                    style={{ backgroundColor: "rgba(237, 58, 58, 0.05)", fontSize: "15px", height: "68px", width: "172px" }}
                                    onClick={() => { this.setState({ delete: false }) }}>

                                    Cancel
                                </Button>
                                <Button variant="contained"
                                    style={{ backgroundColor: "#ED3A3A", fontSize: "15px", height: "68px", width: "172px" }}
                                    onClick={() => {
                                        this.setState({ delete: false })
                                        this.props.deleteAsset(this.props.login.org_id, this.state.assets_id);
                                    }}>

                                    Confirm
                                </Button>


                            </DialogActions>
                        </Grid>
                    </Dialog>

                    <Dialog
                        open={this.state.deleteall}
                        onClose={() => {
                            this.setState({ deleteall: false });
                        }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        style={{ width: "100%", height: "100%", }}
                    >
                        {/* <IconButton
                    > */}

                        <Grid style={{ backgroundColor: "#F8F8F8", height: "320px", minWidth: "380px" }}>


                            <center>
                                <ErrorOutlineOutlinedIcon style={{ marginTop: "3%", fontSize: "84px", color: "#FF0000" }} />
                                <DialogTitle style={{ fontSize: "22px", color: "#FF0000" }}>Are You Sure ?</DialogTitle>
                                <Typography style={{ fontSize: "18px" }}>You want to delete</Typography >

                            </center>
                            <DialogActions style={{ marginTop: "42px" }}>

                                <Button variant="contained"
                                    style={{ backgroundColor: "rgba(237, 58, 58, 0.05)", fontSize: "15px", height: "68px", width: "172px" }}
                                    onClick={() => { this.setState({ deleteall: false }) }}>

                                    Cancel
                                </Button>
                                <Button variant="contained"
                                    style={{ backgroundColor: "#ED3A3A", fontSize: "15px", height: "68px", width: "172px" }}
                                    onClick={() => {
                                        this.setState({ deleteall: false })
                                        this.props.isCheckedId(this.props.login.org_id, this.props.assets.assets)

                                    }}>

                                    Confirm
                                </Button>


                            </DialogActions>
                        </Grid>
                    </Dialog>

                    <Loader />
                    <Snackbar
                        open={snackbar.response_received}
                        close_snack_bar={close_snack_bar}
                        message={snackbar.message}
                    />
                </Grid >

            )
    }
}
