import {
  ASSETS,
  ASSETS_ID,

  SET_ASSETS_NAME,
  SET_DURATION,
  SET_ATTACHMNET,
  SET_ASSETS_ID,
  SET_RENEWAL_DURATION,

  SEARCH_ASSERTS
} from "./constant";
const initial_state = {
  assets: [],
  assets_by_id: [],
  search_asserts: [],

  setAssetsName: "",
  setDuration: "",
  setAttachmnet: "",
  setAssetsId: "",
  setRenewalDuration: "",
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {
    
    case ASSETS:
      return {
        ...state,
        assets: action.payload, search_asserts: action.payload
      };
    case ASSETS_ID:
      return state = { ...state, assets_by_id: action.payload };

    case SET_ASSETS_NAME:
      return state = { ...state, setAssetsName: action.payload };
    case SET_DURATION:
      return state = { ...state, setDuration: action.payload };
    case SET_ATTACHMNET:
      return state = { ...state, setAttachmnet: action.payload };
    case SET_ASSETS_ID:
      return state = { ...state, setAssetsId: action.payload };
    case SET_RENEWAL_DURATION:
      return state = { ...state, setRenewalDuration: action.payload };

    case SEARCH_ASSERTS:
      return {
        ...state,
        search_asserts: action.payload
      };
    default:
      return state;
  }
}
