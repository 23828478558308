import { EDIT_ALL_SERVICES, VIEW_ALL_PRODUCTS, VIEW_ALL_SERVICES } from "./Constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";
import "firebase/storage";
import "firebase/storage";
import { set_snack_bar } from "../../common/snackbar/action";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  uploadString,
} from "firebase/storage";


export function View_All_Service(token) {
  console.log(token)
  return (dispatch) => {
    dispatch(setLoader());
    console.log(token);
    return fetch(
      UNIVERSAL.BASEEMP +
      "get-all-service",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("asiillllllllllllla", responseJson);
        if (responseJson.status) {
          dispatch({
            type: VIEW_ALL_SERVICES,
            payload: {
              data: responseJson.result.data.result,
              count: responseJson.result.data.result.length,
            },
          });
          dispatch(set_snack_bar(true, "Data View Sucessfully"));
          dispatch(unsetLoader());
        } else {
          dispatch({
            type: VIEW_ALL_SERVICES,
            payload: { data: [], count: 0 },
          });
          dispatch(set_snack_bar(true, responseJson.message));
          dispatch(unsetLoader());
        }
        dispatch(unsetLoader());
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function Add_services(service,category, token) {
  console.log(service, token)
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEEMP + "createService", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        category:category,
        sub_category: service,
        // price: price,
      }),
    })
      .then((response) => response.json())

      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.status) {
          dispatch(View_All_Service(token));
          dispatch(unsetLoader());
        } else {
        }
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, responseJson.message));
      })
      .catch((error) => {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));

      });
  };
}
export function addBulkProduct(products, token) {
  console.log(products,token)
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEEMP + "add-bulk-products", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        products:products,  // Send all products as an array
      }),
    })
    .then((response) => response.json())
    .then((responseJson) => {
      console.log(responseJson);
      if (responseJson.status) {
        // Dispatch an action to refresh the products view after successful upload
        dispatch(View_All_Products(token));
        dispatch(unsetLoader());
      } else {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, responseJson.message));
      }
    })
    .catch((error) => {
      dispatch(unsetLoader());
      dispatch(set_snack_bar(true, "Check Your Internet Connection"));
    });
  };
}
export function addBulkservice(category,services,price, token) {
  // console.log(category,services,price,token)
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEEMP + "add-bulk-services", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        category:category, 
        services:services,
        price:price,
         // Send all products as an array
      }),
    })
    .then((response) => response.json())
    .then((responseJson) => {
      console.log(responseJson);
      if (responseJson.status) {
        // Dispatch an action to refresh the products view after successful upload
        dispatch(View_All_Service(token));
        dispatch(unsetLoader());
      } else {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, responseJson.message));
      }
    })
    .catch((error) => {
      dispatch(unsetLoader());
      dispatch(set_snack_bar(true, "Check Your Internet Connection"));
    });
  };
}


export function Delete_all_Services(id, token) {
  console.log(id, token)
  return (dispatch) => {
    console.log(id, token)
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEEMP + "delete-service/" + id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
      },

    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(View_All_Service(token));

          dispatch(unsetLoader());
        } else {

        }
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, responseJson.message));
      })
      .catch((error) => {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function Update_All_Service(id, service, price, token) {
  return (dispatch) => {
    dispatch(setLoader());
    //   console.log(service,price)
    return fetch(UNIVERSAL.BASEEMP + "update-service/" + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify({
        service: service,
        price: price,
      }),
    })
      .then((response) => response.json())

      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.status) {
          dispatch(View_All_Service(token));

          dispatch(unsetLoader());
        } else {

        }
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, responseJson.message));
      })
      .catch((error) => {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}


export function View_All_Products(token) {
  console.log(token)
  return (dispatch) => {
    dispatch(setLoader());
    console.log(token);
    return fetch(
      UNIVERSAL.BASEEMP +
      "get-all-product",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("asiillllllllllllla", responseJson);
        if (responseJson.status) {
          dispatch({
            type: VIEW_ALL_PRODUCTS,
            payload: {
              data: responseJson.result.data.result,
              count: responseJson.result.data.result.length,
            },
          });
          dispatch(set_snack_bar(true, "Data View Sucessfully"));
          dispatch(unsetLoader());
        } else {
          dispatch({
            type: VIEW_ALL_PRODUCTS,
            payload: { data: [], count: 0 },
          });
          dispatch(set_snack_bar(true, responseJson.message));
          dispatch(unsetLoader());
        }
        dispatch(unsetLoader());
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function Add_Products(product, price, token) {
  console.log(product, price, token)
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEEMP + "createProduct", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        product: product,
        price: price,
      }),
    })
      .then((response) => response.json())

      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.status) {
          dispatch(View_All_Products(token));
          dispatch(unsetLoader());
        } else {
        }
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, responseJson.message));
      })
      .catch((error) => {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));

      });
  };
}


export function Delete_all_Products(id, token) {
  console.log(id, token)
  return (dispatch) => {
    console.log(id, token)
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEEMP + "delete-product/" + id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
      },

    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(View_All_Products(token));

          dispatch(unsetLoader());
        } else {

        }
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, responseJson.message));
      })
      .catch((error) => {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}


export function UpdateProduct(id, product, price, token) {
  return (dispatch) => {
    dispatch(setLoader());
    //   console.log(service,price)
    return fetch(UNIVERSAL.BASEEMP + "update-product/" + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify({
        product: product,
        price: price,
      }),
    })
      .then((response) => response.json())

      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.status) {
          dispatch(View_All_Products(token));

          dispatch(unsetLoader());
        } else {

        }
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, responseJson.message));
      })
      .catch((error) => {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}