import React, { Component } from "react";
import { connect } from "react-redux";
import { close_snack_bar } from "../../../common/snackbar/action";
import { Add_Billinvoice, Add_GiftInvoice, Add_ProductInvoice, Get_all_invoice_Gift, Get_all_invoice_product, Update_All_Billinvoice, UpdateGiftinvoice, View_All_BillInvoice, View_All_Servicesalon, viewAllClient,getAllAppoinmentByNumber,clearData,UpdateAppoimentSatus } from "../action";
import { View_All_Service, View_All_Products } from "../../ProductService/action";
import BillInvoice from "../Component/BillInvoice";
export class Controller extends Component {
    render() {
        return <BillInvoice {...this.props} />;
    }
}
export const mapStateToProps = (store) => {
    return {
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
        billinvoice: store.billinvoice,
        viewallservices: store.viewallservices,
        client: store.client,
    };
};
export const mapDispatchToProps = (dispatch) => {
    return {
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        View_All_BillInvoice: (token) => {
            dispatch(View_All_BillInvoice(token));
        },
        Add_Billinvoice: (name, number, service,  price, token) => {
            dispatch(Add_Billinvoice(name, number, service,  price, token))
        },
        Update_All_Billinvoice: (id, name, number, service, discount, token) => {
            dispatch(Update_All_Billinvoice(id, name, number, service, discount, token))
        },
        View_All_Servicesalon: (token) => {
            dispatch(View_All_Servicesalon(token));
        },
        View_All_Products: (token) => {
            dispatch(View_All_Products(token))
        },
        UpdateAppoimentSatus: (token,appointmentid) => {
            dispatch(UpdateAppoimentSatus(token,appointmentid))
        },
        viewAllClient: (token) => {
            dispatch(viewAllClient(token));
        },
        Get_all_invoice_product: (token) => {
            dispatch(Get_all_invoice_product(token))
        },
        Add_ProductInvoice: (name, customerNumber, product, discount, price,gst,token) => {
            dispatch(Add_ProductInvoice(name, customerNumber, product, discount, price,gst, token));
        },
        Get_all_invoice_Gift: (token) => {
            dispatch(Get_all_invoice_Gift(token))
        },
        getAllAppoinmentByNumber: (token,number) => {
            dispatch(getAllAppoinmentByNumber(token,number))
        },
        clearData: (payload) => {
            dispatch(clearData(payload))
        },
        Add_GiftInvoice: (name, customerNumber, giftName, packageDetails, expDate, couponCode, price, token) => {
            console.log(name, customerNumber, giftName, packageDetails, expDate, couponCode, price, token);
            dispatch(Add_GiftInvoice(name, customerNumber, giftName, packageDetails, expDate, couponCode, price, token))
        },
        UpdateGiftinvoice:(id,giftName,customerNumber,name,packageDetails,expDate,couponCode,price,token)=>{
            dispatch(UpdateGiftinvoice(id,giftName,customerNumber,name,packageDetails,expDate,couponCode,price,token))
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
