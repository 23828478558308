import { SET_FILTERED_VALUE, VIEW_ALL_CLIENT } from "./Constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";
import "firebase/storage";
import "firebase/storage";
import { set_snack_bar } from "../../common/snackbar/action";
import {
    getStorage,
    ref,
    uploadBytesResumable,
    getDownloadURL,
    uploadString,
} from "firebase/storage";


export function viewAllClient(token) {
    console.log(token)
    return (dispatch) => {
        dispatch(setLoader());
        console.log(token);
        return fetch(
            UNIVERSAL.BASEEMP +
            "get-all-clients",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        )
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("asiillllllllllllla", responseJson);
                if (responseJson.status) {
                    dispatch({
                        type: VIEW_ALL_CLIENT,
                        payload: {
                            data: responseJson.result.data.result,
                            count: responseJson.result.data.result.length,
                        },
                    });
                    dispatch(set_snack_bar(true, "Data View Sucessfully"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({
                        type: VIEW_ALL_CLIENT,
                        payload: { data: [], count: 0 },
                    });
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}



export function Add_services(service,price, token) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEEMP + "/createService", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: token,
            },
            body: JSON.stringify({
                service:service,
                price:price,
            }),
        })
            .then((response) => response.json())

            .then((responseJson) => {
                console.log(responseJson);
                if (responseJson.status) {
                    dispatch(viewAllClient(token));
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));

            });
    };
}

// export function Delete_all_Services(
//     id, token
//   ) {
  
//     return (dispatch) => {
//       dispatch(setLoader());
      
//       return fetch(UNIVERSAL.BASEURLVENDOR + "delete-service/" + id, {
//         method: "DELETE",
//         headers: {
//           Accept: "application/json",
//           "Content-Type": "application/json",
//           Authorization: token,
//         },
//       })
//         .then((response) => response.json())
//         .then((responseJson) => {
//           if (responseJson.status) {
//             dispatch(View_All_Service(token));
//             dispatch(unsetLoader());
//           } else {
           
//           }
//           dispatch(unsetLoader());
//           dispatch(set_snack_bar(true, responseJson.message));
//         })
//         .catch((error) => {
//           dispatch(unsetLoader());
//           dispatch(set_snack_bar(true, "Check Your Internet Connection"));
//           console.error(error);
//         });
//     };
//   }




  export function Delete_all_Services(id,token) {
    console.log(id,token)
    return (dispatch) => {
        console.log(id,token)
      dispatch(setLoader());
      return fetch(UNIVERSAL.BASEEMP + "delete-service/" + id, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token
        },
      
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status) {
            dispatch(viewAllClient(token));
           
            dispatch(unsetLoader());
          } else {
           
          }
          dispatch(unsetLoader());
          dispatch(set_snack_bar(true, responseJson.message));
        })
        .catch((error) => {
          dispatch(unsetLoader());
          dispatch(set_snack_bar(true, "Check Your Internet Connection"));
          console.error(error);
        });
    };
  }

 export function searchClinet(view_all_client, searchValue) {  // Renamed 'e' to 'searchValue'
    
  return (dispatch) => {
    // If search value is empty, reset the filtered list to original data
    if (searchValue === "") {
      dispatch({ type: SET_FILTERED_VALUE, payload: view_all_client });
    } else {
      // Filter the view_all_client array by customerNumber matching searchValue
      const filteredArray = view_all_client.filter((client) => 
        client.customerNumber.toLowerCase().includes(searchValue.toLowerCase())
      );
      
      dispatch({ type: SET_FILTERED_VALUE, payload: filteredArray });
    }
  };
}
