import React, { Component } from "react";
import { connect } from "react-redux";
import { close_snack_bar } from "../../../common/snackbar/action";
import { Get_All_Appointment, Get_All_Appointment_date, UpdateClientNote } from "../action";
// import ProductService from "../Component/ProductService";
import UserAppoitment from "../Component/UserAppoitment";
export class Controller extends Component {
    render() {
        return <UserAppoitment {...this.props} />;
    }
}
export const mapStateToProps = (store) => {
    return {
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
        sawappoitment: store.sawappoitment,
    };
};
export const mapDispatchToProps = (dispatch) => {
    return {
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        Get_All_Appointment: (token) => {
            dispatch(Get_All_Appointment(token));
        },
        Get_All_Appointment_date: (date, token) => {
            dispatch(Get_All_Appointment_date(date, token));
        },
        UpdateClientNote: (customerNumber, note, token) => {
            dispatch(UpdateClientNote(customerNumber, note, token))
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
