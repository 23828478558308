// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ModalComponent.css */

.Gift_button__ujgrJ {
    margin: 20px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .Gift_modal-overlay__UAWvH {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .Gift_modal-content__9GF\\+v {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 500px;
    position: relative;
  }
  
  .Gift_modal-close__frWlw {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Gifts/Component/Gift.module.css"],"names":[],"mappings":"AAAA,uBAAuB;;AAEvB;IACI,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,UAAU;IACV,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,eAAe;EACjB","sourcesContent":["/* ModalComponent.css */\n\n.button {\n    margin: 20px;\n    padding: 10px 20px;\n    font-size: 16px;\n    cursor: pointer;\n  }\n  \n  .modal-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.5);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  .modal-content {\n    background: #fff;\n    padding: 20px;\n    border-radius: 8px;\n    width: 80%;\n    max-width: 500px;\n    position: relative;\n  }\n  \n  .modal-close {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    background: none;\n    border: none;\n    font-size: 24px;\n    cursor: pointer;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `Gift_button__ujgrJ`,
	"modal-overlay": `Gift_modal-overlay__UAWvH`,
	"modal-content": `Gift_modal-content__9GF+v`,
	"modal-close": `Gift_modal-close__frWlw`
};
export default ___CSS_LOADER_EXPORT___;
