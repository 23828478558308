import { SET_APPOINTMENT, SET_APPOINTMENT_SLOT, SET_APPOINTMENT_SLOT_VIEW, VIEW_APPOINTMENT_BY_DATE } from "./constant";
const initial_state = {
  all_appointment: [],
  all_appointment_slot: [],
  all_appointment_slot_view: [],
  view_appointment_bydate: [],

  count: 0,

};
export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_APPOINTMENT:
      return (state = {
        ...state,
        all_appointment: action.payload.data,
        count: action.payload.count,
      });
    case VIEW_APPOINTMENT_BY_DATE:
      return (state = {
        ...state,
        view_appointment_bydate: action.payload.data,
        count: action.payload.count,
      });
    case SET_APPOINTMENT_SLOT:
      return (state = {
        ...state,
        all_appointment_slot: action.payload.data,
        count: action.payload.count,
      });
    case SET_APPOINTMENT_SLOT_VIEW:
      return (state = {
        ...state,
        all_appointment_slot_view: action.payload.data,
        count: action.payload.count,
      });

    default:
      return state;
  }
}