import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Controller from "../containers/controller_cont";
import Login from "../../auth/container/logincont";
import Assets from "../../pages/Assets/container/assetsCont";
import Error from "../../404";
import Test from "../../pages/Assets/Components/Test";
// import BillInvoice from "../../pages/BillInvoice/Component/BillInvoice";
// import ProductService from "../../pages/ProductService/Component/ProductService";
import Gifts from "../../pages/Gifts/Container/giftCont";
import AllClients from "../../pages/AllClients/Container/allClientCont";
import AllEmployee from "../../pages/AllEmployee/container/AllEmployeeCont";
import AdminAssets from "../../pages/Assets/Components/adminAssets";
// import UserAppoitment from "../../pages/UserAppoitment/Component/UserAppoitment";
import Grid from '@mui/material/Grid';
import Drawer_option_con from "../containers/drawer_cont";
import ProductService from "../../pages/ProductService/Component/ProductService";
import ProductServiceCont from "../../pages/ProductService/Container/ProductServiceCont";
import Appointment from "../../pages/Appoitment/container/AppointmentCont";
import UserAppoitmentCont from "../../pages/UserAppoitment/Container/UserAppoitmentCont";
import BillInvoiceCont from "../../pages/BillInvoice/Container/BillInvoiceCont";

class Routers extends Component {
  render() {
    const { login, onLogout } = this.props;
    let body;
    console.log(login)
    if (login.isHome) {
      body = (
        <div style={{background: "#FBF8FF"}}>
          <main
            style={{
              flexGrow: 1,
            }}
          >
            <Controller />
            <Routes>
              <Route exact path="/" element={<Login />} />
              <Route exact path="*" element={<Error />} />
            </Routes>
          </main>
        </div>
      );
    }
    else {
      if (login.role === "admin") {
        body = (
          <div
            style={{
              // display: "flex",
              
            }}
          >
          
          <Drawer_option_con
              type={login.role}
              onLogout={onLogout}
              name={login.name}
            />
            <main
              style={{
                flexGrow: 1,
                marginTop: 100,
                marginLeft: 150,
              }}
            >
              <Controller />
              <Grid
            
            >
                <Routes>
                <Route exact path="/" element={<Appointment />} />
                <Route exact path="/billInvoice" element={<BillInvoiceCont />} />
                <Route exact path="/productService" element={<ProductServiceCont />} />
                <Route exact path="/productService" element={<ProductServiceCont />} />
                <Route exact path="/gifts" element={<Gifts />} />
                <Route exact path="/allClients" element={<AllClients />} />
                <Route exact path="/allEmployee" element={<AllEmployee />} />
                <Route exact path="/adminAssets" element={<AdminAssets />} />
                <Route exact path="/assets" element={<Assets />} /> 
               
                <Route exact path="/test" element={<Test/>} /> 
                {/* <Route exact path="/viewemployee" element={<UserAppoitmentCont />} /> */}
              </Routes>
              </Grid>
            </main>
          
          </div>
        );
      }
      else if (login.role === "employee") {
        body = (
          <div
            style={{
              // display: "flex",
              
            }}
          >
          <Drawer_option_con
              type={login.role}
              onLogout={onLogout}
              name={login.name}
            /> 
            <main
              style={{
                flexGrow: 1,
                marginTop: 80,
                marginLeft: 150,
              }}
            >
              <Controller />
              <Grid
            >
                <Routes>
                <Route exact path="/" element={<UserAppoitmentCont />} />
              </Routes>
              </Grid>
            </main>
          
          </div>
        );
      }
     
    }
 
    return <Router>{body}</Router>;
  }
}

export default Routers; 