// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
   
    padding: 10.5px 14px !important;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root {
   
    text-transform: none !important;
}

.css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
    text-transform: none;
    
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    box-shadow: none;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    min-width: 40px;
}

.attactment{
    width: 500px !important;
    height: 80.35px !important; 
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 7px;
    background-color: #FCFCFC;
    margin-left: 1.5%
}`, "",{"version":3,"sources":["webpack://./src/pages/Assets/style/Assets.css"],"names":[],"mappings":"AAAA;;IAEI,+BAA+B;AACnC;;AAEA;;IAEI,+BAA+B;AACnC;;AAEA;IACI,oBAAoB;;AAExB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,uBAAuB;IACvB,0BAA0B;IAC1B,oCAAoC;IACpC,kBAAkB;IAClB,yBAAyB;IACzB;AACJ","sourcesContent":[".css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {\r\n   \r\n    padding: 10.5px 14px !important;\r\n}\r\n\r\n.css-sghohy-MuiButtonBase-root-MuiButton-root {\r\n   \r\n    text-transform: none !important;\r\n}\r\n\r\n.css-1rwt2y5-MuiButtonBase-root-MuiButton-root {\r\n    text-transform: none;\r\n    \r\n}\r\n\r\n.css-11xur9t-MuiPaper-root-MuiTableContainer-root {\r\n    box-shadow: none;\r\n}\r\n\r\n.css-1e6y48t-MuiButtonBase-root-MuiButton-root {\r\n    min-width: 40px;\r\n}\r\n\r\n.attactment{\r\n    width: 500px !important;\r\n    height: 80.35px !important; \r\n    border: 1px solid rgba(0, 0, 0, 0.4);\r\n    border-radius: 7px;\r\n    background-color: #FCFCFC;\r\n    margin-left: 1.5%\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
