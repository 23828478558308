import { GET_ALLGIFT_INVOICE, GET_ALLPRODUCT_INVOICE,GET_ALL_PRICE_BY_NUMBER, VIEW_ALL_BILLINVOICE,VIEW_ALL_SERVICES } from "./constant";
const initial_state = {
    view_all_billinvoice: [],
    get_all_productinvoice: [],
    get_all_giftinvoice: [],
    view_all_service: [],
    get_all_price_by_number: [],
    count: 0,
};
export default function reducer(state = initial_state, action) {
    switch (action.type) {
        case VIEW_ALL_BILLINVOICE:
            return (state = {
                ...state,
                view_all_billinvoice: action.payload.data,
                count: action.payload.count,
            });
            case VIEW_ALL_SERVICES:
                return (state = {
                    ...state,
                    view_all_service: action.payload.data,
                    count: action.payload.count,
                });
        case GET_ALLPRODUCT_INVOICE:
            return (state = {
                ...state, get_all_productinvoice: action.payload.data,
                count: action.payload.count,
            });
        case GET_ALL_PRICE_BY_NUMBER:
            return (state = {
                ...state, get_all_price_by_number: action.payload.data,
                count: action.payload.count,
            });
        case GET_ALLGIFT_INVOICE:
            return (state = {
                ...state, get_all_giftinvoice: action.payload.data,
                count: action.payload.count,
            });
        default:
            return state;
    }
}