import React, { Component } from "react";
import { connect } from "react-redux";
import { close_snack_bar } from "../../../common/snackbar/action";
import { Add_Products, Add_services, Delete_all_Products, Delete_all_Services, Update_All_Service, View_All_Products, View_All_Service,UpdateProduct,addBulkProduct, addBulkservice } from "../action";
import ProductService from "../Component/ProductService";
export class Controller extends Component {
  render() {
    return <ProductService {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    viewallservices: store.viewallservices,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    View_All_Service: (token) => {
      dispatch(View_All_Service(token));
    },
    Add_services: (service, sub_category, token) => {
      dispatch(Add_services(service, sub_category, token))
    },
    Delete_all_Services: (id, token) => {
      dispatch(Delete_all_Services(id, token));
      // console.log(id);
    },
    Update_All_Service: (id, service, price, token) => {
      dispatch(Update_All_Service(id, service, price, token));
      console.log(id, service);
    },
    UpdateProduct: (id, product, price, token) => {
      dispatch(UpdateProduct(id, product, price, token));
      // console.log(id, service);
    },
    View_All_Products: (token) => {
      dispatch(View_All_Products(token))
    },
    Add_Products: (product, price, token) => {
      dispatch( Add_Products(product, price, token))
      console.log(product, price);
    },
    addBulkProduct:(product, token) => {
      dispatch( addBulkProduct(product, token))
      // console.log(product, price);
    },
    addBulkservice:(category,services,price, token)=>{
      dispatch(addBulkservice(category,services,price, token))
    },
    Delete_all_Products: (id, token) => {
      dispatch(Delete_all_Products(id, token));
      // console.log(id);
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
