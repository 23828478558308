import { SET_ALLEMPLOYEE} from "./constant";
const initial_state = {
  all_allemployee: [],
 
  count: 0,
 
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_ALLEMPLOYEE:
      return (state = {
        ...state,
        all_allemployee: action.payload.data,
        count: action.payload.count,
      });
     
    default:
      return state;
  }
}