import { LOGIN, LOGOUT } from "./constant";

const initial_state = {
  role: " ",
  isHome: true,
  token: null, 
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isHome: false,
        orgHome: true,
        token: action.payload.token, // Store token in state
        role:action.payload.role
      };

    case LOGOUT:
      return {
        ...state,
        isHome: true,
        user_id: "",
        type: "",
        mobile: "",
        name: "",
        email: "",
        token: null, // Clear token on logout
      };

    default:
      return state;
  }
}
