import { SET_APPOINTMENT,SET_APPOINTMENT_SLOT,SET_APPOINTMENT_SLOT_VIEW, VIEW_APPOINTMENT_BY_DATE} from "./constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";

import "firebase/storage";
// import firebase from 'firebase/app'
import "firebase/storage";
import { set_snack_bar } from "../../common/snackbar/action";

export function view_all_appointment(token) {
    return (dispatch) => {
      dispatch(setLoader());
      return fetch(
        UNIVERSAL.BASEEMP + "get-all-appointments",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,  
          },
        }
      )
        .then((response) => {
          if (response.status === 401) {
            throw new Error("Unauthorized - Invalid Token");
          }
          return response.json();
        })
   
        .then((responseJson) => {
            console.log(responseJson)
          if (responseJson.status) {
            dispatch({
              type: SET_APPOINTMENT,
              payload: {
                data: responseJson.result.data.result,
                count: responseJson.result.data.result.length,
              },
            });
            dispatch(set_snack_bar(true, "Data View Successfully"));
          } else {
            dispatch({
              type: SET_APPOINTMENT,
              payload: { data: [], count: 0 },
            });
            dispatch(set_snack_bar(true, responseJson.message));
          }
        })
        .catch((error) => {
          console.error("Error: ", error);
          dispatch(set_snack_bar(true, error.message));
        })
        .finally(() => {
          dispatch(unsetLoader());
        });
    };
  }
  export function addnewappointment( phn, name,dob,service,date,assignEmployee,timeslot,message, token ) {
   return (dispatch) => {
    console.log( phn, name,dob,service,date,assignEmployee,timeslot,message, token)
     dispatch(setLoader());
     return fetch(UNIVERSAL.BASEEMP + "addAppointments", {
       method: "POST",
       headers: {
         Accept: "application/json",
         "Content-Type":"application/json",
         Authorization: token
       },
       body: JSON.stringify({
        customerNumber: phn,
        name: name,
        dob:dob,
        service:service,
        date:date,
        assignEmployee:assignEmployee,
        timeslot:timeslot,
        message:message,
 
       }),
     })
       .then((response) => response.json())
       .then((responseJson) => {
         if (responseJson.status) {
           dispatch(view_all_appointment(token));
           dispatch(unsetLoader());
         } else {
          
          dispatch({
            type: SET_APPOINTMENT,
            payload: { data: [], count: 0 },
          });
          dispatch(set_snack_bar(true, responseJson.message));
          dispatch(unsetLoader());
        }
      })
       .catch((error) => {
         dispatch(unsetLoader());
         dispatch(set_snack_bar(true, "Check Your Internet Connection"));
         console.error(error);
       });
   };
 }
 export function updatenewappointment(id, phn, name,dob,service,date,assignEmployee,timeslot,message, token ) {
  return (dispatch) => {
  //  console.log (id, phn, name,dob,service,date,assignEmployee,timeslot,message, token )
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEEMP + "update-appointment/" +id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type":"application/json",
        Authorization: token
      },
      body: JSON.stringify({
       customerNumber: phn,
       name: name,
       dob:dob,
       service:service,
       date:date,
       assignEmployee:assignEmployee,
       timeslot:timeslot,
       message:message,

      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(view_all_appointment(token));
          dispatch(unsetLoader());
        } else {
         
         dispatch({
           type: SET_APPOINTMENT,
           payload: { data: [], count: 0 },
         });
         dispatch(set_snack_bar(true, responseJson.message));
         dispatch(unsetLoader());
       }
     })
       
  
      .catch((error) => {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
 export function delete_appointment(appointmentid,token) {
    return (dispatch) => {
      dispatch(setLoader());
      return fetch(UNIVERSAL.BASEEMP + 'delete-appointment/'+appointmentid, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token
        },
      
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status) {
            dispatch(view_all_appointment(token));
           
            dispatch(unsetLoader());
          } else {
          
          }
          dispatch(unsetLoader());
          dispatch(set_snack_bar(true, responseJson.message));
        })
        .catch((error) => {
          dispatch(unsetLoader());
          dispatch(set_snack_bar(true, "Check Your Internet Connection"));
          console.error(error);
        });
    };
}
export function slotavailable(service,date,timeslot, token ) {
  return (dispatch) => {
   console.log( service,date,timeslot, token)
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEEMP + "choose-emp-for-appointment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type":"application/json",
        Authorization: token
      },
      body: JSON.stringify({
        service: service,
        date: date,
        timeslot:timeslot,

      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch({
            type: SET_APPOINTMENT_SLOT,
            payload: {
              data: responseJson.result.data.result,
              count: responseJson.result.data.result.length,
            },
          })
        
        } else {
         
        }
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, responseJson.message));
      })
      .catch((error) => {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function slotavailableview(empId,date, token ) {
  return (dispatch) => {
   console.log( date, token)
    dispatch(setLoader());
    return fetch(`${UNIVERSAL.BASEEMP}viewAppointmentByDateAndEmpId?empId=${encodeURIComponent(empId)}&date=${encodeURIComponent(date)}` , {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type":"application/json",
        Authorization: token
      },
      // body: JSON.stringify({
      //   service: service,
      //   date: date,
      //   timeslot:timeslot,

      // }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson)
        if (responseJson.status) {
          dispatch({
            type: SET_APPOINTMENT_SLOT_VIEW,
            payload: {
              data: responseJson.result.data.result,
              count: responseJson.result.data.result.length,
            },
          })
        
        } else {
         
        }
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, responseJson.message));
      })
      .catch((error) => {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function setFreeSlot(payload) {
  return {
      type: SET_APPOINTMENT_SLOT_VIEW,
      payload: payload,
  };
}


export function view_all_appointmentByDate(date,token ) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(
      UNIVERSAL.BASEEMP + "viewAppointmentsByDate",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,  
        },
      }
    )
      .then((response) => {
        if (response.status === 401) {
          throw new Error("Unauthorized - Invalid Token");
        }
        return response.json();
      })
 
      .then((responseJson) => {
          console.log(responseJson)
        if (responseJson.status) {
          dispatch({
            type: VIEW_APPOINTMENT_BY_DATE,
            payload: {
              data: responseJson.result.data.result,
              count: responseJson.result.data.result.length,
            },
          });
          dispatch(set_snack_bar(true, "Data View Successfully"));
        } else {
          dispatch({
            type: SET_APPOINTMENT,
            payload: { data: [], count: 0 },
          });
          dispatch(set_snack_bar(true, responseJson.message));
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
        dispatch(set_snack_bar(true, error.message));
      })
      .finally(() => {
        dispatch(unsetLoader());
      });
  };
}