import React, { Component } from "react";
import { connect } from "react-redux";
import { close_snack_bar } from "../../../common/snackbar/action";
import { Add_services, Delete_all_Services, viewAllClient,searchClinet} from "../action";
import ProductService from "../Component/AllClients";
export class Controller extends Component {
  render() {
    return <ProductService {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    client: store.client,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    viewAllClient: (token) => {
      dispatch(viewAllClient(token));
    },
    Add_services:(service,price, token)=>{
      dispatch(Add_services(service,price, token))
    },
    Delete_all_Services: (id,token) => {
      dispatch(Delete_all_Services(id,token));
      // console.log(id);
    },
    searchClinet: (model,e) => {
      dispatch(searchClinet(model,e));
      // console.log(id);
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
