import { SET_ALLEMPLOYEE} from "./constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";
// import { set_snack_bar } from "../../common/snackbar/action";
// import { getStorage,ref,uploadBytesResumable,getDownloadURL   } from "firebase/storage";
import "firebase/storage";
// import firebase from 'firebase/app'
import "firebase/storage";
import { set_snack_bar } from "../../common/snackbar/action";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  uploadString,
} from "firebase/storage";
export function view_all_employee(token) {
    return (dispatch) => {
      dispatch(setLoader());
      console.log("Token being sent: ", token);  
      return fetch(
        UNIVERSAL.BASEEMP + "view-all-employee",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token
          },
        }
      )
        .then((response) => {
          if (response.status === 401) {
            throw new Error("Unauthorized - Invalid Token");
          }
          return response.json();
        })
   
        .then((responseJson) => {
            console.log(responseJson)
          if (responseJson.status) {
            dispatch({
              type: SET_ALLEMPLOYEE,
              payload: {
                data: responseJson.result.data.result,
                count: responseJson.result.data.result.length,
              },
            });
            dispatch(set_snack_bar(true, "Data View Successfully"));
          } else {
            dispatch({
              type: SET_ALLEMPLOYEE,
              payload: { data: [], count: 0 },
            });
            dispatch(set_snack_bar(true, responseJson.message));
          }
        })
        .catch((error) => {
          console.error("Error: ", error);
          dispatch(set_snack_bar(true, error.message));
        })
        .finally(() => {
          dispatch(unsetLoader());
        });
    };
  }
  export function addnewemployee( name,contactNo,email,password,AltNumber, token ) {
    console.log( name,contactNo,email,password,AltNumber, token)
   return (dispatch) => {
     dispatch(setLoader());
     // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
     return fetch(UNIVERSAL.BASEEMP + "add-employee", {
       method: "POST",
       headers: {
         Accept: "application/json",
         "Content-Type":"application/json",
         Authorization: token
               },
       body: JSON.stringify({
        // service: service,
        name: name,
        contactNo:contactNo,
        email:email,
        password:password,
        AltNumber:AltNumber,
         // type: "AU"
       }),
     })
       .then((response) => response.json())
       .then((responseJson) => {
         if (responseJson.status) {
           dispatch(view_all_employee(token));
           // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
           dispatch(unsetLoader());
         } else {
           // alert("No Registration Found! Please Register First");
           // dispatch({ type: SET_USER, payload: [] })
           // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
         }
         dispatch(unsetLoader());
         dispatch(set_snack_bar(true, responseJson.message));
       })
       .catch((error) => {
         dispatch(unsetLoader());
         dispatch(set_snack_bar(true, "Check Your Internet Connection"));
         console.error(error);
       });
   };
 }
 export function update_employee(empid, name,contactNo,email,password,AltNumber, token)
     {
    
    return (dispatch) => {
      dispatch(setLoader());
      // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
      console.log(empid);
      return fetch(UNIVERSAL.BASEEMP + "update-employee/"+ empid, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token
                },
        body: JSON.stringify({   
         
            name:name,
            contactNo:contactNo,
            email:email,
           
            password:password,
            AltNumber:AltNumber,
        }),
      })
        .then((response) => response.json())
  
        .then((responseJson) => {
          console.log(responseJson);
          if (responseJson.status) {
            dispatch(view_all_employee(token));
            // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
            dispatch(unsetLoader());
          } else {
            // alert("No Registration Found! Please Register First");
            // dispatch({ type: SET_USER, payload: [] })
            // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
          }
          dispatch(unsetLoader());
          dispatch(set_snack_bar(true, responseJson.message));
        })
        .catch((error) => {
          dispatch(unsetLoader());
          dispatch(set_snack_bar(true, "Check Your Internet Connection"));
          console.error(error);
        });
    };
  }
 export function delete_employee(employeeid,token) {
    return (dispatch) => {
      dispatch(setLoader());
      return fetch(UNIVERSAL.BASEEMP + 'delete-employee/'+employeeid, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token
                },
        // body: JSON.stringify({
        //   id: attributeid,
        // }),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status) {
            dispatch(view_all_employee(token));
           
            dispatch(unsetLoader());
          } else {
            // alert("No Registration Found! Please Register First");
            // dispatch({ type: SET_USER, payload: [] })
            // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
          }
          dispatch(unsetLoader());
          dispatch(set_snack_bar(true, responseJson.message));
        })
        .catch((error) => {
          dispatch(unsetLoader());
          dispatch(set_snack_bar(true, "Check Your Internet Connection"));
          console.error(error);
        });
    };
}