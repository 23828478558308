import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import {
  Card,
  TextField,
  Button,
  Typography,
  Grid,
  Box,
  IconButton,
  InputAdornment,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Snackbar from '../../common/snackbar/components/snackbar';
import LoaderCon from '../../common/loader/containers/loader_cont';
import Logo from "../../media/img/WhatsApp Image 2024-08-23 at 12.32.17 PM 1.png"
import './logincont.css';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      showPassword: false,
      emailError: '',
      passwordError: '',
    };
  }

  handleClickShowPassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  validatePassword = (password) => {
    return password.length >= 2;
  };

  validateFields = () => {
    const { email, password } = this.state;
    let isValid = true;

    if (!this.validateEmail(email)) {
      this.setState({ emailError: 'Please enter a valid email' });
      isValid = false;
    } else {
      this.setState({ emailError: '' });
    }

    if (!this.validatePassword(password)) {
      this.setState({ passwordError: 'Please enter a valid password' });
      isValid = false;
    } else {
      this.setState({ passwordError: '' });
    }

    return isValid;
  };

  handleSubmit = () => {
    const isValid = this.validateFields();

    if (isValid) {
      this.props.super_login(this.state.email, this.state.password);
      this.setState({ redirect: true });
    } else {
      console.log('Form is invalid');
    }
  };
  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.handleSubmit();
    }
  };

  render() {
    const { email, password, showPassword, emailError, passwordError } = this.state;

    if (this.props.login?.user_id) {
      return <Navigate to='/dashboard' />;
    }

    return (
      <>
        <div className="login-container">
        <div className="login-form">
          <img
            src={Logo}
            alt="Logo"
            className="login-logo"
            style={{width:"70px",height:"70px"}}
          />
          <h2 className="login-title">LOGIN</h2>
          <label className='nme'>Email I’d</label>
          <TextField
                // label="Email"
                name="email"
                value={email}
                onChange={this.handleChange}
                error={!!emailError}
                helperText={emailError}
                fullWidth
                margin="normal"
                variant="outlined"
                onKeyPress={this.handleKeyPress} 
                InputLabelProps={{
                  shrink: true,
                }}
              />
               <label className='nme'>Password</label>
              <TextField
                // label="Password"
                name="password"
                value={password}
                onChange={this.handleChange}
                error={!!passwordError}
                onKeyPress={this.handleKeyPress} 
                helperText={passwordError}
                type={showPassword ? 'text' : 'password'}
                fullWidth
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
                <Button
                className='login-button'
                variant="contained"
                color="primary"
                fullWidth
                onClick={this.handleSubmit}
                // style={{ marginTop: '20px', backgroundColor: '#4AA3DA' }}
              >
                Login
              </Button>
        </div>
        <div className="login-image">
          {/* Background image goes here */}
        </div>
      </div>
      <LoaderCon />
              <Snackbar
                open={this.props.snackbar?.response_received}
                close_snack_bar={this.props.close_snack_bar}
                message={this.props.snackbar?.message}
              />
      </>
    );
  }
}

export default Login;
