import {
    LOGIN,
    LOGOUT,
    CLEAR_LOGIN,
    SET_ADMIN_ID,
    SETPROFILE,
  } from "./constant";
  import UNIVERSAL from "../config/config";
  import { setLoader, unsetLoader } from "../common/loader/action";
  import { set_snack_bar } from "../common/snackbar/action";

  
  export function super_login(email, password) {
    // console.log(email, password)
    return async (dispatch) => {
      dispatch(setLoader());
      try {
        const response = await fetch(UNIVERSAL.BASEURL +"login", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            password: password,
            // type: "U",
          }),
        });
        const responseJson = await response.json();
        console.log("asilllllll", responseJson);
        if (responseJson.status) {
          dispatch(setAdminLogin(responseJson.result.data.result));
          dispatch({
            type: SETPROFILE,
            payload: { data: responseJson.result.data.result},
          });
          dispatch(setAdminId(responseJson.result.data.result.token));
          dispatch(set_snack_bar(responseJson.status, responseJson.displayMessage));
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.displayMessage));
        }
      } catch (error) {
        console.error(error);
        dispatch(set_snack_bar(error.message, "Connection failed"));
      } finally {
        dispatch(unsetLoader());
      }
      dispatch(unsetLoader());
    };
  }
  export function clear_login() {
    return {
      type: CLEAR_LOGIN,
    };
  }
  export function setAdminId(payload) {
    return {
      type: SET_ADMIN_ID,
      payload: payload,
    };
  }
  
  export function setAdminLogin(payload) {
  
    localStorage.setItem("token", payload.token);
    localStorage.setItem("role", payload.role);
  
    return {
      type: LOGIN,
      payload: payload,
    };
  }
  

  export function onLogout() {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
  
    return {
      type: LOGOUT,
    };
  }
  
  export function register_user(name, email, password, mobile) { 
    return async (dispatch) => {
      dispatch(setLoader());
      try {
        const response = await fetch(UNIVERSAL.BASEURL + "user/register", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: name,
            email: email,
            password: password,
            mobileNumber: mobile,
          }),
        });
        const responseJson = await response.json();
        console.log("asilllllll", responseJson);
        if (responseJson.status) {
          // dispatch(setAdminLogin(responseJson.result.data));
  
          // dispatch(setAdminId(responseJson.result.data.token));
          dispatch(set_snack_bar(responseJson.status, "Register Successfully"));
        } else {
          dispatch(set_snack_bar(responseJson.status, "Invalid User"));
        }
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(unsetLoader());
      }
    };
  }
  // export function Update_upload_brand(
  //   id,brandTitle, brandSlug,editpreview, brandLogo,featured,token
  // ) {
  //   console.log(id,brandTitle, brandSlug,editpreview, brandLogo,featured,token);
  //   return (dispatch) => {
  //     dispatch(setLoader(true));
  //     if(brandLogo === null){
  //       dispatch(UpdateVendorBrand(
  //         id,brandTitle, brandSlug,editpreview,featured,token
  //       )
  //     );
  //     }else{
  
  //     //  var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
  //     const storageRef = ref(getStorage(), "/Brand/" + brandTitle + ".png");
  //     const uploadTask = uploadBytesResumable(storageRef, brandLogo);
  //     uploadTask.on(
  //       "state_changed",
  //       function (snapshot) {},
  //       function (error) {
  //         console.log(error);
  //         dispatch(setLoader(false));
  //       },
  //       function () {
  //         getDownloadURL(uploadTask.snapshot.ref).then(function (brandLogo) {
  //           console.log(brandLogo);
  //           dispatch(
  //             UpdateVendorBrand(
  //               id, brandTitle, brandSlug, brandLogo,featured,token
  //             )
  //           );
  //         });
  //       }
  //     );
  //   }
  //   };
  // }
  
  // export function UpdateVendorBrand(
  //   id, brandTitle, brandSlug, brandLogo,featured,token
  // ) {
  //  console.log(brandTitle, brandSlug, brandLogo,featured,token)
  //   return (dispatch) => {
  //     dispatch(setLoader());
  //     // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
  //     return fetch(UNIVERSAL.BASEURLVENDOR + "vendor-brand-req/"+ id, {
  //       method: "PUT",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         Authorization: token,
  //       },
  //       body: JSON.stringify({
  //         title:brandTitle ,
  //         slug: brandSlug,
  //         logo: brandLogo,
  //         featured: featured,
  //       }),
  //     })
  //       .then((response) => response.json())
  //       .then((responseJson) => {
  //         if (responseJson.status) {
  //           dispatch(viewBrandReq(token));
  //           dispatch(unsetLoader());
  //         } else {
  //           // alert("No Registration Found! Please Register First");
  //           // dispatch({ type: SET_USER, payload: [] })
  //           // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
  //         }
  //         dispatch(unsetLoader());
  //         dispatch(set_snack_bar(true, responseJson.message));
  //       })
  //       .catch((error) => {
  //         dispatch(unsetLoader());
  //         dispatch(set_snack_bar(true, "Check Your Internet Connection"));
  //         console.error(error);
  //       });
  //   };
  // }
  