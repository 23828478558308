import { VIEW_ALL_APPOINTMENT_DATE, VIEW_ALL_APPOINTMENTS } from "./constant";
const initial_state = {
    get_all_appointment: [],
    get_all_appointment_date: [],
    count: 0,
};
export default function reducer(state = initial_state, action) {
    switch (action.type) {
        case VIEW_ALL_APPOINTMENTS:
            return (state = {
                ...state,
                get_all_appointment: action.payload.data,
                count: action.payload.count,
            });
        case VIEW_ALL_APPOINTMENT_DATE:
            return (state = {
                ...state,
                get_all_appointment_date: action.payload.data,
                count: action.payload.count,
            });
        default:
            return state;
    }
}