import React, { useState, useEffect } from 'react';
import './AllEmployee.css';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TextField } from '@mui/material';
import { DialogTitle, DialogContent, DialogActions, } from "@mui/material";
// import inputlogo from '../../Assets/inputlogo.png';
import { FaEdit, FaEye, FaTrashAlt } from 'react-icons/fa';
import { Checkbox, ListItemText } from '@mui/material';
import { IoCloseSharp } from "react-icons/io5";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Appointment(props) {
    const handleSave = (e) => {
        e.preventDefault();
    
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
        if (!name || !number || !email || !password) {
            toast.error("Please fill out all fields.");
        } else if (!emailPattern.test(email)) {
            toast.error("Please enter a valid email address.");
        } else if (number.length !== 10) {
            toast.error("Number must be exactly 10 digits.");
        } else {
            saveemployee();
            toast.success("Employee added successfully!");
            handleCloseModal();
        }
    };
    
    const handleSaveedit = () => {
     
            props.update_employee(empid, editName, editNumber, editEmail, editPassword, editAltNumber, props.login.token);
            toast.success("Employee updated successfully!");
            seteditModalOpen(false);
       
        
    };
    const [selectedClient, setSelectedClient] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [editModalOpen, seteditModalOpen] = useState(false)
    const [empid, setempId] = useState("");
    const [open, setOpen] = useState(false);
    const [name, setName] = useState("");
    const [service, setService] = useState([]);
    const [number, setNumber] = useState("");
    const [email, setEmail] = useState("");
    const [altNumber, setAltNumber] = useState(0);
    const [password, setPassword] = useState("");
    const [editName, setEditName] = useState("");
    const [editService, setEditService] = useState([]);
    const [editNumber, setEditNumber] = useState('');
    const [editEmail, setEditEmail] = useState('');
    const [editAltNumber, setEditAltNumber] = useState('');
    const [editPassword, setEditPassword] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [uniqueCategories, setUniqueCategories] = useState([]);

    // Assuming view_all_service is passed as a prop
    const allServices = props.viewallservices.view_all_service;

    useEffect(() => {
        // Get unique categories from the data
        const categories = Array.from(new Set(allServices.map(service => service.category)));
        setUniqueCategories(categories);
    }, [allServices]);
    useEffect(() => {
        props.view_all_employee(props.login.token);
        return () => { };
    }, []);
    // console.log(props.viewallservices.view_all_service)
    useEffect(() => {
        let isCancelled = false;
        if (!isCancelled) {
            // props.add_new_product(props.login._id);
            props.View_All_Service(props.login.token);
        }
        return () => {
            isCancelled = true;
        };
    }, [props.login._id]);
    const handleCategoryChange = (event) => {
        const category = event.target.value;
        setSelectedCategory(category);

        // Filter and get unique sub-categories for the selected category
        // const subCategories = allServices
        //     .filter(service => service.category === category)
        //     .flatMap(service => service.sub_category)
        //     .map(sub => sub.name); // Assuming sub_category is an array of objects with a 'name' property

        // setUniqueSubCategories(Array.from(new Set(subCategories))); // Get unique sub-categories
        // setSelectedSubCategory([]); // Reset selected sub-categories when category changes
    };

    // const handleSubCategoryChange = (event) => {
    //     const {
    //         target: { value },
    //     } = event;
    //     // Convert the value to an array for multiple selection
    //     setSelectedSubCategory(
    //         typeof value === 'string' ? value.split(',') : value,
    //     );
    // };
    const [client, setclient] = React.useState('');
    const handleViewDetails = (client) => {
        setSelectedClient(client);
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setSelectedClient(null);
    };
    const handleChangeclient = (event) => {
        setclient(event.target.value);
    };
    const handleServiceChange = (event) => {
        setService(event.target.value);
    };
    const handleeditServiceChange = (event) => {
        setEditService(event.target.value);
    };
    // Extract unique services from the props
    const getUniqueServices = () => {
        const allServices = props.viewallservices.view_all_service.flatMap(allservice => allservice.service);
        return Array.from(new Set(allServices));
    };
    console.log(props.viewallservices.view_all_service)
    const notify = () => toast.warn("Deleted Successfully!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    const uniqueServices = getUniqueServices();
    const saveemployee = () => {
        // Call the action to add a new employee
        props.addnewemployee(name, number, email, password, altNumber, props.login.token);
        // Reset the state
        setName("");
     
        setNumber("");
        setEmail("");
        setAltNumber("0000000000");
        setPassword("");
        setIsModalOpen(false);
        // Show success toast notification
       

        // Log the state after resetting (for debugging)
        console.log('State after reset:');
  
        console.log('Name:', name);
        console.log('Number:', number);
        console.log('Email:', email);
        console.log('Alt Number:', altNumber);
        console.log('Password:', password);
    };


    const [selectedItem, setSelectedItem] = useState('');

    const handleSelectChange = (event) => {
        setSelectedItem(event.target.value);
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };
    const handleCloseModal = () => {
        setName("");
        setService([]);
        setNumber("");
        setEmail("");
        setAltNumber("");
        setPassword("");
        setIsModalOpen(false);
    };
    const [select, setselect] = React.useState('');
    const handleChange = (event) => {
        setselect(event.target.value);
    };

    return (
        <div className='surround'>
            <div>
                <div className='appointment-text'>
                    <h1>
                        All Employee
                    </h1>
                    <div className='appointment-button'>
                        <Button className="appointmentopen-modals" onClick={handleOpenModal}>
                            Add Employee
                        </Button>
                    </div>
                </div>
            </div>
            {/* table start */}
            <div className='appointment-main'>
                <TableContainer component={Paper}>
                    <Table className="styled-table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ color: '#fff' }}>S.No</TableCell>
                                <TableCell style={{ color: '#fff' }}>Name</TableCell>
                                <TableCell style={{ color: '#fff' }}>Email</TableCell>
                                <TableCell style={{ color: '#fff' }}>Phone</TableCell>
                                {/* <TableCell>Password</TableCell> */}
                                <TableCell style={{ color: '#fff' }}>Alt Number</TableCell>
                                <TableCell style={{ color: '#fff' }}></TableCell>
                                <TableCell style={{ color: '#fff' }}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Array.isArray(props.allemployee.all_allemployee) && props.allemployee.all_allemployee.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>{row.email}</TableCell>
                                    <TableCell>{row.contactNo}</TableCell>
                                    {/* <TableCell>{row.password}</TableCell> */}
                                    <TableCell>{row.AltNumber}</TableCell>
                                    <TableCell>{row.category}</TableCell>

                                    <TableCell>
                                        <IconButton size="small" onClick={() => {
                                            seteditModalOpen(true);
                                            setempId(row._id);
                                            setEditName(row.name);
                                            setEditService(row.service)
                                            setEditAltNumber(row.AltNumber)
                                            setEditNumber(row.contactNo)
                                            setEditEmail(row.email)
                                            setEditPassword(row.password)
                                        }

                                        }>
                                            <FaEdit style={{ color: 'green' }} />
                                        </IconButton>
                                        <IconButton size="small">
                                            <FaEye style={{ color: 'purple' }} onClick={() => handleViewDetails(row)} />
                                        </IconButton>
                                        <IconButton size="small" onClick={() => {
                                            setOpen(true);
                                            setempId(row._id);
                                        }}>
                                            <FaTrashAlt style={{ color: 'red' }} />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            {/* table end */}

            {/* modal */}
            {isModalOpen && (
                <Dialog open={isModalOpen} onClose={handleCloseModal} maxWidth="sm" fullWidth>
                    <div className="appointment-overlay">
                        <div className="appointment-content">
                            <button className="appointment-close" onClick={handleCloseModal}>
                                &times;
                            </button>
                            <h2>Add Employee</h2>
                            <hr className='appointment-line' />
                            <div className="form-container">
                                <div className="form-row">
                                    <div className="form-group">
                                        <label>Name</label>
                                        <input
                                            type="text"
                                            name="name"
                                            placeholder="Name of the Employee"
                                            value={name}
                                            required
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </div>

                                </div>

                                <div className="form-row">
                                    <div className="form-group">
                                        <label>Number</label>
                                        <input
                                            type="number"
                                            name="number"
                                            value={number}
                                            onChange={(e) => setNumber(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input
                                            type="email"
                                            name="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group">
                                        <label>Alt Number</label>
                                        <input
                                            type="number"
                                            name="altNumber"
                                            value={altNumber}
                                            onChange={(e) => setAltNumber(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Password</label>
                                        <input
                                            type="password"
                                            name="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <br />
                                <div className="appointment-form-button">
                                    <button type="submit" onClick={handleSave} style={{ background: '#607d8b' }}>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            )}
            {/* {edit modal} */}
            <Dialog open={editModalOpen} onClose={() => seteditModalOpen(false)} maxWidth="sm" fullWidth>
                <div className="appointment-overlay">
                    <div className="appointment-content">
                        <Button
                            onClick={() => seteditModalOpen(false)}
                            style={{
                                position: 'absolute',
                                top: 10,
                                right: 10,
                                backgroundColor: '#f5f5f5',
                                borderRadius: '20%',
                                width: 40,
                                height: 40,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                                cursor: 'pointer',
                                zIndex: 1
                            }}
                        >
                            <IoCloseSharp style={{ fontSize: 24, color: '#333' }} />
                        </Button>
                        <h2>Edit Employee</h2>
                        <hr className='appointment-line' />
                        <div className="form-container">
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Name</label>
                                    <TextField
                                        type="text"
                                        name="name"
                                        placeholder="Name of the employee"
                                        value={editName}
                                        onChange={(e) => setEditName(e.target.value)}
                                        fullWidth
                                    />
                                </div>
                              
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Number</label>
                                    <TextField
                                        type="number"
                                        name="number"
                                        value={editNumber}
                                        onChange={(e) => setEditNumber(e.target.value)}
                                        fullWidth
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Email</label>
                                    <TextField
                                        type="email"
                                        name="email"
                                        value={editEmail}
                                        onChange={(e) => setEditEmail(e.target.value)}
                                        fullWidth
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Alt Number</label>
                                    <TextField
                                        type="number"
                                        name="altNumber"
                                        value={editAltNumber}
                                        onChange={(e) => setEditAltNumber(e.target.value)}
                                        fullWidth
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Password</label>
                                    <TextField
                                        type="password"
                                        name="password"
                                        value={editPassword}
                                        onChange={(e) => setEditPassword(e.target.value)}
                                        fullWidth
                                    />
                                </div>
                            </div>
                            <br />
                            <div className="appointment-form-button">
                                <Button
                                    type="button"
                                    variant="contained"
                                    onClick={
                                        handleSaveedit
                                    }
                                    style={{ background: '#607d8b' }}
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            {/* {delete modal} */}
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>Are you sure you want to delete?</DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            props.delete_employee(empid, props.login.token);
                            setOpen(false);
                            notify();
                        }}
                        variant="contained"
                        color="primary"
                        sx={{ background: "red" }}
                    >
                        Yes
                    </Button>
                    <Button
                        onClick={() => setOpen(false)}
                        variant="contained"
                        color="secondary"
                        sx={{ background: "green" }}
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
            {/* view emp detials */}
            {selectedClient && (
                <Dialog open={isDialogOpen} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
                    <div style={{ padding: "10px" }}>
                        <h2>Employee Details</h2>
                        <p><strong>Name:</strong> {selectedClient.name}</p>
                        <p><strong>Email</strong> {selectedClient.email}</p>
                        <p><strong>Mobile Number:</strong> {selectedClient.contactNo}</p>
                  
                        <p><strong>AltNumber</strong> {selectedClient.AltNumber}</p>
                        {/* <p><strong>Message:</strong> {selectedClient.message}</p> */}
                        {/* <p><strong>Timeslot:</strong> {selectedClient.timeslot}</p>
                        <p><strong>Date:</strong> {new Date(selectedClient.date).toLocaleString('en-US', {
                            weekday: 'long',
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true,
                        })}</p> */}
                        <center>


                            <button style={{ color: "white", backgroundColor: "#6A8C9F", border: "1px solid #6A8C9F", padding: "10px", margin: "10px", borderRadius: "10px" }} onClick={handleCloseDialog}>Close</button>
                        </center>
                    </div>
                </Dialog>
            )}
            <ToastContainer />
        </div>
    )
}
export default Appointment;
