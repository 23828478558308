export const ASSETS="ASSETS";
export const ASSETS_ID="ASSETS_ID";

export const SET_ASSETS_NAME="SET_ASSETS_NAME";
export const SET_DURATION="SET_DURATION";
export const SET_ATTACHMNET="SET_ATTACHMNET";
export const SET_ASSETS_ID="SET_ASSETS_ID";
export const SET_RENEWAL_DURATION="SET_RENEWAL_DURATION";

export const SEARCH_ASSERTS="SEARCH_ASSERTS";
