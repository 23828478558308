import { SET_FILTERED_VALUE, VIEW_ALL_CLIENT } from "./Constant";
const initial_state = {
    view_all_client: [],
    filtered_model: [],
    count: 0,
};
export default function reducer(state = initial_state, action) {
    switch (action.type) {
        case VIEW_ALL_CLIENT:
            return (state = {
                ...state,
                view_all_client: action.payload.data,filtered_model:action.payload.data,
                count: action.payload.count,
            });
            case SET_FILTERED_VALUE:
                return state = { ...state, filtered_model: action.payload };
    
        default:
            return state;
    }
}