import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from 'firebase/storage';

export const CLEAR_LOGIN = "CLEAR_LOGIN";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SETPROFILE = "SETPROFILE";
export const SET_ADMIN_ID = "SET_ADMIN_ID";



// export const VIEW_ALL_PROJECT_TYPE = "VIEW_ALL_PROJECT_TYPE";

const firebaseConfig = {
  apiKey: "AIzaSyBGU54CrrHxcMRNRL9jAQ3dL4GOIUNqG-A",
  authDomain: "hair-dextrity.firebaseapp.com",
  projectId: "hair-dextrity",
  storageBucket: "hair-dextrity.appspot.com",
  messagingSenderId: "309068845885",
  appId: "1:309068845885:web:06c3a62b9bc2ba8eb4eb54",
  measurementId: "G-3J5NXGQDQK"
};
export const app = initializeApp(firebaseConfig);
const storage = getStorage(app); // Initialize Firebase Storage
export { storage };

// export const fb = firebase.initializeApp(firebaseConfig);